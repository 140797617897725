import ProfileBubble from "@/components/sections/ProfileBubble";
import { ClearButton } from "@/components/ui/Button";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import ExpandoText from "@/components/ui/ExpandoText";
import { useEntityStore } from "@/stores/entityStore";
import { EntityFact, EntityType, ProfilePageSection } from "@/types";
import { PencilIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";

export default withErrorBoundary(function AboutSection() {
  const entityStore = useEntityStore();
  const snapshot = useStore(entityStore.snapshot);
  const canEdit = useStore(entityStore.canEdit);

  const aboutSection = snapshot?.facts?.[EntityFact.About]?.value;
  if (!aboutSection && !canEdit) return null;

  const title = snapshot.entityType == EntityType.Person ? "About me" : "About";

  const edit = () => {
    entityStore.showFactEditModal.set({
      type: EntityFact.About,
      currentValue: aboutSection || "",
    });
  };

  return (
    <ProfileBubble
      className="flex flex-col gap-2 relative group"
      title={title}
      section={ProfilePageSection.About}
      titleButtons={
        <>
          {canEdit && (
            <PencilIcon
              className="-mt-1 w-4 h-4 text-gray-400 hover:text-brand-600 hidden group-hover:block cursor-pointer"
              onClick={edit}
            />
          )}
        </>
      }
    >
      <div className="whitespace-pre-wrap">
        {aboutSection ?
          <ExpandoText text={aboutSection} limit={500} truncateSlashes={false} />
        : <div className="text-gray-400">No description</div>}
      </div>
    </ProfileBubble>
  );
});
