/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/sections/ProfileBubble";
import SocialPosts from "@/components/sections/SocialPosts";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { titleCase } from "@/lib/utils";
import { useEntityStore } from "@/stores/entityStore";
import { ProfilePageSection } from "@/types";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { Fragment } from "react";

type Props = {};

export const getSocialLogo = (service: string, url: string) => {
  if (service === "twitter") {
    return (
      <img
        src={`/images/twitter.svg`} // clearbit doesn't have twitter logo anymore apparently
        className="rounded max-w-full max-h-full object-contain h-8 w-8 text-white p-1 bg-black"
        aria-hidden="true"
        alt="logo"
      />
    );
  }

  let domain;
  try {
    domain = new URL(url).hostname;
  } catch (e) {
    domain = `${service}.com`;
  }

  return (
    <img
      src={`https://logo.clearbit.com/${domain}`}
      className="rounded max-w-full max-h-full object-contain h-8 w-8 text-black"
      aria-hidden="true"
      alt="logo"
    />
  );
};

export default withErrorBoundary(function SocialProfilesV2(props: Props) {
  const entityStore = useEntityStore();
  const socials = useStore(entityStore.socials);
  if (!socials.length) return null;

  return (
    <ProfileBubble title={`Other Profiles`} section={ProfilePageSection.SocialMedia}>
      <div className="flex flex-col gap-4 overflow-hidden">
        {socials.map((item, i) => (
          <Fragment key={item.url}>
            <a className="flex" href={item.url} target="_blank" rel="noreferrer">
              <div className="p-1 mr-2">{getSocialLogo(item.service, item.url)}</div>
              <div className="flex-1">
                <div className="flex items-center">
                  <span className="font-bold">{titleCase(item.service || "")}</span>
                  <ArrowTopRightOnSquareIcon className="ml-2 h-4 w-4 text-gray-400" />
                  <span className="ml-2 text-gray-400">{item.username}</span>
                </div>
                <div className="flex flex-wrap items-center text-gray-400">
                  {[
                    item.followers && `${item.followers.toLocaleString()} followers`,
                    item.following && `${item.following.toLocaleString()} following`,
                    item.connections && `${item.connections.toLocaleString()} connections`,
                    // TODO: add recent posts back, need to update twitter api to get recent post count
                    // item.recentPostCount !== undefined &&
                    //   item.recentPostCount !== null &&
                    //   `${item.recentPostCount} recent posts`,
                  ]
                    .filter(Boolean)
                    .join(" • ")}
                </div>
              </div>
            </a>
          </Fragment>
        ))}
      </div>
    </ProfileBubble>
  );
});
