import CompanyStats from "@/components/companies/CompanyStats";
import ShowPipelineStatus from "@/components/entities/ShowPipelineStatus";
import PastExperiences, { InvestmentList } from "@/components/experiences/PastExperiences";
import AboutSection from "@/components/sections/AboutSection";
import AuthoredMedia from "@/components/sections/AuthoredMedia";
import CompanyFunding from "@/components/sections/CompanyFunding";
import CompanyPeopleSection from "@/components/sections/CompanyPeopleSection";

import EntityHeader from "@/components/sections/EntityHeader";
import MediaCoverage from "@/components/sections/MediaCoverage";
import MutualConnections from "@/components/sections/MutualConnections";
import SocialProfiles from "@/components/sections/SocialProfiles";
import Sources from "@/components/sections/Sources";
import { useEntityStore } from "@/stores/entityStore";
import { EntityType } from "@/types";
import { useStore } from "@nanostores/react";

interface Props {
  // Whether the component is being rendered as the main entity, or
  // in some auxiliary setting.
  main?: boolean;
}

export default function EntityContent({ main = false }: Props) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const status = useStore(entityStore.initialStatus);

  return (
    <>
      <EntityHeader status={status} main={main} showAIChat={true} />
      <ShowPipelineStatus status={status} />
      <AboutSection />
      {entity.type === EntityType.Person && <MutualConnections wide={main} />}
      {entity.type === EntityType.Company && <CompanyStats />}
      {entity.type === EntityType.Company && <CompanyPeopleSection />}
      {entity.type === EntityType.Company && <CompanyFunding />}
      <AuthoredMedia />
      <MediaCoverage />
      {entity.type === EntityType.Person && <PastExperiences />}
      {entity.type === EntityType.Company && <InvestmentList />}
      <SocialProfiles />
      <Sources />
    </>
  );
}
