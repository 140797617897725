import moment from "moment";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

import { MessageActions } from "@/components/messages/MessageActions";
import Avatar from "@/components/ui/Avatar";
import { classNames, titleCase } from "@/lib/utils";
import { uiStore } from "@/stores/uiStore";
import { ChatRole, UIMessage } from "@/types";
import { BookOpenIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";

import { MessageBody } from "./MessageBody";

export type MessageProps = {
  message: UIMessage;
  pos: { i: number; len: number };
  showAuthor?: boolean;
  nested?: boolean;
  hasActions?: boolean;
  style?: MessageStyle;
};

export type GroupableMessageProps = MessageProps & {
  message: MessageUnit;
};

export type GroupedMessage = {
  id: string | undefined;
  role: ChatRole;
  content: string | undefined;
  messages: UIMessage[];
  hasActions?: boolean;
};

export type MessageUnit = GroupedMessage | UIMessage;

export type MessageStyle = "bubble" | "label" | "entity";

export const isGroupedMessage = (message: MessageUnit): message is GroupedMessage => {
  return !!(message as GroupedMessage)?.messages;
};

export default function Message(props: GroupableMessageProps) {
  const { message, showAuthor, style = "bubble" } = props;

  if (message.role == ChatRole.System) {
    return <div className="self-center text-sm text-gray-500">{message.content}</div>;
  }

  if (message.role == ChatRole.Error) {
    return <div className="self-center text-sm text-red-600">{message.content}</div>;
  }

  const reverse = style == "bubble" && message.role == ChatRole.User;
  return (
    <div
      className={classNames(
        "flex gap-2 print:max-w-none",
        "max-w-3xl",
        "hover:bg-gray-50",
        showAuthor && "mt-4",
        reverse && "self-end flex-row-reverse",
      )}
    >
      {showAuthor ?
        <MessageAvatar {...props} variant={style} />
      : <div className="w-6" />}
      <div className="group flex-1 flex flex-col">
        {style == "label" && showAuthor && <MessageAuthor {...props} />}
        {isGroupedMessage(message) ?
          <GroupedMessageUnit {...props} message={message} />
        : <MessageContents {...props} />}
      </div>
    </div>
  );
}

const MessageContents = ({ message, pos, hasActions, style }: MessageProps) => {
  const [expanded, setExpanded] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    if (!contentRef.current) return;
    const threshold = contentRef.current.scrollHeight - contentRef.current.offsetHeight;
    const hasContent = !!message.content;
    const isLatest = pos.i < 2;
    setHasMore(threshold > 20);
    setExpanded(hasContent && (isLatest || threshold <= 20));
  }, [message, pos]);

  if (!message) return null;

  const content = message.content || "";

  const output = content;
  let postMessageAction: JSX.Element | undefined;

  const margin = message.content || expanded ? "mb-2" : "";

  return (
    <div className="w-full relative">
      {hasActions && <MessageActions message={message} pos={pos} />}
      <div
        className={twMerge(
          `w-full print:max-w-none text-slate-800 dark:text-slate-100 text-sm markdown`,
          "max-w-2xl",
          margin,
        )}
      >
        <div
          ref={contentRef}
          className={twJoin(
            "overflow-hidden ease-out rounded-md",
            expanded ? "" : "max-h-60",
            style == "bubble" && "rounded-md py-1 px-3",
            style == "bubble" &&
              (message.role == ChatRole.User ? "border"
              : message.role == ChatRole.Assistant ? "bg-gray-100"
              : null),
          )}
        >
          {output && <MessageBody content={output} />}
        </div>

        {hasMore && !expanded && (
          <div
            onClick={() => {
              setExpanded(true);
            }}
            className={`absolute bottom-0 left-0 right-0 flex flex-col text-xs text-blue-500 cursor-pointer`}
          >
            <div className="bg-gradient-to-b from-transparent to-white h-10" />
            <div className="bg-white">Click to view full message</div>
          </div>
        )}
      </div>
      {pos.i == pos.len - 1 ? postMessageAction : null}
    </div>
  );
};

export const MessageAvatar = ({
  message,
  variant,
}: {
  message: UIMessage;
  variant?: MessageStyle;
}) => {
  const user = useStore(uiStore.user);
  const size = variant == "entity" ? "h-7 w-7" : "h-6 w-6";

  return (
    message.role == ChatRole.Assistant ?
      <Image
        src="/favicon-32x32.png"
        alt="Assistant"
        width={32}
        height={32}
        className={twJoin("mt-1", size)}
      />
    : message.role == ChatRole.Error ?
      <ExclamationCircleIcon className={twJoin("text-red-600", size)} />
    : message.role == ChatRole.Tool ? <BookOpenIcon className={twJoin("text-blue-600", size)} />
    : message.role == "user" && user ? <Avatar user={user} className={twJoin("mt-1", size)} />
    : <div className="w-6" />
  );
};

export const MessageAuthor = ({ message }: { message: UIMessage }) => {
  const messageAuthor =
    message.role == ChatRole.Assistant ? "Assistant"
    : message.role == ChatRole.User ? "You"
    : message.role == ChatRole.Tool ? "Research Helper"
    : titleCase(message.role);
  return (
    <div className="flex gap-2 items-center">
      <div className="text-sm font-bold dark:text-slate-100">{messageAuthor}</div>
      <div className="text-xs text-gray-500 dark:text-gray-400">
        {moment(message.createdAt).fromNow()}
      </div>
    </div>
  );
};

function GroupedMessageUnit({
  message: group,
  pos,
}: {
  message: GroupedMessage;
  pos: { i: number; len: number };
}) {
  const [expanded, setExpanded] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const expand = () => {
    if (expanded) {
      setExpanded(false);
    } else {
      setExpanded(true);
      setTimeout(() => divRef.current?.scrollIntoView({ behavior: "smooth" }), 100);
    }
  };

  return (
    <div ref={divRef} className={classNames("-ml-4 pl-4", expanded && "border-l")}>
      {expanded && (
        <div className="flex flex-col-reverse gap-2">
          {group.messages.map((m, i) => (
            <MessageContents key={i} message={m} pos={pos} showAuthor={false} />
          ))}
        </div>
      )}

      <div className="flex gap-4">
        <div
          className="text-gray-500 text-xs cursor-pointer -mx-2 my-1 p-2 hover:bg-gray-50 rounded-md"
          onClick={expand}
        >
          {expanded ? "Hide" : "Show"} work
        </div>
      </div>
    </div>
  );
}
