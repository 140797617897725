import { Attributes } from "@opentelemetry/api";
import { atom, computed, map } from "nanostores";
import { toast } from "react-toastify";

import API from "@/client/api";
import { CreateListData, UpdateListData } from "@/client/lists";
import errorTracker from "@/lib/errorTracker";
import { loggerWithPrefix } from "@/lib/logger";
import eventTracker from "@/lib/trackers/eventTracker";
import { prettyError, updateUnsubscribe } from "@/lib/utils";
import { ListEntrySnapshotsResult, ListEntryUpdate, UpdateListViewParams } from "@/lists";
import { uiStore } from "@/stores/uiStore";
import {
  ListCustomFieldDefinition,
  ListCustomFieldValue,
  ListDetails,
  ListEntryCustomFieldSpec,
  ListEntryDetails,
  ListEntrySnapshot,
  ListEntrySpec,
  ListMaxSizeReachedError,
  ListOverview,
  ListRoutes,
  ListView,
  PagingOptions,
} from "@/types";
import { ListInvite, ListUser, User } from "@prisma/client";

const logger = loggerWithPrefix("[lists-store]");

type UpdateViewParams = Omit<UpdateListViewParams["data"], "listId" | "viewId">;
type Channel = {
  unsubscribe: () => void;
};

type EntityProgressUpdate = {
  label: string;
  stepsLeft: number;
  estimatedSecs: number;
};

type EntryUpdateMessage = {
  type: "create" | "update" | "delete";
  entries: ListEntryDetails[] | string[]; // string[] for delete (entry IDs)
  listId: string;
};

export class ListStore {
  readonly list = atom<ListDetails | null>(null);
  listRole = atom<"owner" | "member" | null>(null);
  readonly snapshots = atom<ListEntrySnapshot[] | null>(null);
  readonly totalSnapshots = atom(0);
  readonly view = atom<ListView | null>(null);
  readonly entries = atom<ListEntryDetails[] | null>(null);

  readonly page = atom(0);
  readonly pageSize = atom(50);
  readonly totalEntries = atom(0);

  // This is set by a feature flag in useUIStore
  readonly maxSize = atom<number>(100);
  readonly maxSizeReached = computed([this.maxSize, this.totalEntries], (maxSize, totalEntries) => {
    return totalEntries >= maxSize;
  });

  readonly listOverviews = atom<ListOverview[] | null>(null);
  readonly totalListOverviews = atom(0);

  private readonly subscriptions: Record<string, Channel> = {};
  readonly entitiesInProgress = map<Record<string, EntityProgressUpdate | undefined>>({});

  readonly entriesWithQueuedEntities = computed(this.entries, (entries) => {
    if (!entries) return [];
    return entries.filter((entry) => {
      const { entity } = entry;
      if (!entity) return false;
      return !entity.generatedAt;
    });
  });

  readonly invites = atom<(ListInvite & { joinedUser: User | null })[] | null>(null);
  readonly listUsers = atom<(ListUser & { user: User })[] | null>(null);

  private listEntriesChannel: Channel | null = null;

  private cleanupListSubscription = () => {
    if (this.listEntriesChannel) {
      updateUnsubscribe(this.listEntriesChannel, () => {
        this.listEntriesChannel?.unsubscribe();
        this.listEntriesChannel = null;
      });
    }
  };

  private subscribeToListEntries = async (listId: string) => {
    if (!uiStore.user.get()) {
      logger.debug("No user found, skipping subscription");
      return;
    }

    // Cleanup any existing subscription
    this.cleanupListSubscription();

    const realtime = await uiStore.getConnectedRealtime();
    const channelName = `list-entries:${listId}`;
    logger.debug("Subscribing to channel", channelName);
    const channel = realtime.channels.get(channelName);

    if (!channel) {
      logger.error("No channel found for list entries", listId);
      return;
    }

    this.listEntriesChannel = channel;

    channel.subscribe("entry-update", (msg) => {
      logger.debug("Received entry update", msg);
      const update = msg.data as EntryUpdateMessage;

      // Ignore updates from the current user's session
      if (msg.connectionId === realtime.connection.id) {
        logger.debug("Ignoring update from current session");
        return;
      }

      switch (update.type) {
        case "create":
          this.handleRemoteEntryCreate(update.entries as ListEntryDetails[]);
          break;
        case "update":
          this.handleRemoteEntryUpdate(update.entries as ListEntryDetails[]);
          break;
        case "delete":
          this.handleRemoteEntryDelete(update.entries as string[]);
          break;
      }
    });
  };

  private handleRemoteEntryCreate = (newEntries: ListEntryDetails[]) => {
    const currentEntries = this.entries.get() || [];
    const totalEntries = this.totalEntries.get();

    // Filter out any entries that already exist
    const uniqueNewEntries = newEntries.filter(
      (newEntry) => !currentEntries.some((existing) => existing.id === newEntry.id),
    );

    if (uniqueNewEntries.length === 0) return;

    this.entries.set([...currentEntries, ...uniqueNewEntries].sort((a, b) => a.order - b.order));
    this.totalEntries.set(totalEntries + uniqueNewEntries.length);
  };

  private handleRemoteEntryUpdate = (updatedEntries: ListEntryDetails[]) => {
    const currentEntries = this.entries.get() || [];
    const updatedEntriesMap = new Map(updatedEntries.map((entry) => [entry.id, entry]));
    const mergedEntries = currentEntries.map((entry) => updatedEntriesMap.get(entry.id) || entry);

    this.entries.set(mergedEntries);
  };

  private handleRemoteEntryDelete = (deletedEntryIds: string[]) => {
    const currentEntries = this.entries.get() || [];
    const currentTotal = this.totalEntries.get();

    const remainingEntries = currentEntries.filter((entry) => !deletedEntryIds.includes(entry.id));

    const deletedCount = currentEntries.length - remainingEntries.length;

    if (deletedCount > 0) {
      this.entries.set(remainingEntries);
      this.totalEntries.set(currentTotal - deletedCount);
    }
  };

  onListChange = (listDetails: ListDetails | null) => {
    // Cleanup existing subscription
    this.cleanupListSubscription();

    if (listDetails) {
      if (listDetails.views.length > 0) {
        this.view.set(listDetails.views[0]);
      } else {
        this.view.set(null);
      }
      void this.loadEntries(listDetails.id, { page: 0, pageSize: this.pageSize.get() });
      void this.loadListMemberships();
      // Subscribe to list entries updates
      void this.subscribeToListEntries(listDetails.id);
    } else {
      this.entries.set(null);
      this.view.set(null);
    }
    void this.loadOverviews();
  };

  onViewChange = (view: ListView | null) => {
    this.snapshots.set(null);
  };

  onEntriesChange = (entries: readonly ListEntryDetails[] | null) => {
    if (!entries) {
      this.totalEntries.set(0);
    }
  };

  onSnapshotsChange = (snapshots: readonly ListEntrySnapshot[] | null) => {
    if (!snapshots) {
      this.totalSnapshots.set(0);
    }
  };

  onEntriesWithQueuedEntitiesChange = (entries: readonly ListEntryDetails[] | null) => {
    if (!entries) {
      return;
    }
    entries.forEach((entry) => {
      if (entry.entityId && !this.subscriptions[entry.entityId]) {
        void this.subscribeToEntity(entry.entityId);
      }
    });
  };

  onOverviewsChange = (overviews: readonly ListOverview[] | null) => {
    if (!overviews) {
      this.totalListOverviews.set(0);
    }
  };

  private reportError(e: unknown, metadata?: Attributes) {
    const msg = prettyError(e);
    toast.error(msg);
    errorTracker.sendError(e, metadata);
  }

  private requireList() {
    const list = this.list.get();
    if (!list) {
      logger.error("No list found");
      throw new Error("No list found");
    }
    return list;
  }

  private requireView() {
    const view = this.view.get();
    if (!view) {
      logger.error("No view found");
      throw new Error("No view found");
    }
    return view;
  }

  loadOverviews = async (paging: PagingOptions = { page: 0, pageSize: 1000 }) => {
    try {
      const { result, total } = await API.lists.list(paging);
      this.listOverviews.set(result);
      this.totalListOverviews.set(total);
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  loadList = async (id: string) => {
    try {
      this.list.set(null);
      const list = await API.lists.get(id);
      this.list.set(list);
    } catch (e: unknown) {
      this.list.set(null);
      this.reportError(e, { listId: id });
    }
  };

  loadEntries = async (listId: string, paging: PagingOptions) => {
    try {
      if (!paging.pageSize) paging.pageSize = this.pageSize.get();
      this.page.set(paging.page);
      const { result: entries, total } = await API.listEntries.list(listId, paging);
      this.totalEntries.set(total);
      this.entries.set(entries);
    } catch (e: unknown) {
      this.entries.set(null);
      this.reportError(e, { listId });
    }
  };

  loadSnapshots = async (options: {
    paging: PagingOptions;
  }): Promise<ListEntrySnapshotsResult | null> => {
    const { paging } = options;
    const view = this.requireView();
    try {
      const result = await API.listViews.snapshots(view.id, {
        ...paging,
        listId: view.list.id,
      });
      const snapshots = paging.page === 0 ? [] : this.snapshots.get() || [];
      this.snapshots.set([...snapshots, ...result.result]);
      this.totalSnapshots.set(result.total);
      return result;
    } catch (e: unknown) {
      this.reportError(e, { viewId: view.id });
      return null;
    }
  };

  loadInvites = async () => {
    try {
      const list = this.requireList();
      const invites = await API.listInvites.list(list);
      this.invites.set(invites);
    } catch (e: unknown) {
      this.reportError(e, { listId: this.list.get()?.id });
    }
  };

  loadListUsers = async () => {
    try {
      const list = this.requireList();
      const users = await API.listUsers.list(list);
      this.listUsers.set(users);
    } catch (e: unknown) {
      this.reportError(e, { listId: this.list.get()?.id });
    }
  };

  loadListMemberships = async () => {
    try {
      await Promise.all([this.loadListUsers(), this.loadInvites()]);
    } catch (e: unknown) {
      this.reportError(e, { listId: this.list.get()?.id });
    }
  };

  // Omitting and assigning default values for customFields and name
  createList = async ({
    name = `List #${this.totalListOverviews.get() + 1}`,
    customFields = [{ type: "email" }],
    ...data
  }: Omit<CreateListData, "name" | "customFields"> & {
    name?: string;
    customFields?: ListCustomFieldDefinition[];
  }) => {
    try {
      const newList = await API.lists.create({ ...data, name });
      this.list.set(newList);
      eventTracker.capture("new-list", {
        listName: newList.name,
        listId: newList.id,
      });
      return newList;
    } catch (e: unknown) {
      this.reportError(e, { name });
    }
  };

  updateList = async (data: UpdateListData) => {
    try {
      const updatedList = await API.lists.update(data.listId, data);
      this.list.set(updatedList);
    } catch (e: unknown) {
      this.reportError(e, { listId: data.listId });
    }
  };

  deleteList = async (id: string) => {
    const currentOverviews = this.listOverviews.get() || [];
    try {
      // Optimistically remove the list from the list of overviews
      this.listOverviews.set(currentOverviews.filter((overview) => overview.id !== id));
      await API.lists.delete(id);
      this.list.set(null);
      if (this.totalListOverviews.get()) {
        this.totalListOverviews.set(this.totalListOverviews.get() - 1);
      }
    } catch (e: unknown) {
      this.reportError(e, { listId: id });
    }
  };

  getEntriesByEntityId = async (listId: string, entityId: string) => {
    try {
      const { result } = await API.listEntries.list(listId, { entityId, page: 0, pageSize: 100 });
      return result as ListEntryDetails[];
    } catch (e: unknown) {
      this.reportError(e, { listId, entityId });
      return false;
    }
  };

  private validateEntries(entrySpecs: readonly ListEntrySpec[]) {
    const currentSize = this.totalEntries.get();
    const maxSize = this.maxSize.get();
    const newSize = currentSize + entrySpecs.length;
    if (newSize > maxSize) {
      const listId = this.requireList().id;
      throw new ListMaxSizeReachedError({
        listId,
        currentSize,
        newSize,
        maxSize,
      });
    }
  }

  private async optimisticUpdateEntries(updates: ListEntryUpdate[], listId: string) {
    // Get current entries
    const currentEntries = this.entries.get() || [];

    // Create map of updates for easier lookup
    const updatesMap = new Map(updates.map((update) => [update.entryId, update]));

    // Update entries locally
    const updatedEntries = currentEntries.map((entry) => {
      const update = updatesMap.get(entry.id);
      if (update) {
        return this.mergeEntry(entry, update);
      }
      return entry;
    });

    // Set optimistic update
    this.entries.set(updatedEntries);

    // Make API call
    await API.listEntries.update(listId, { entries: updates });
  }

  private mergeEntry(existing: ListEntryDetails, update: ListEntryUpdate): ListEntryDetails {
    const { data: existingData, customFields: existingCustomFields } = existing;
    const { data: updatedData, customFields: updatedCustomFields, ...updateRest } = update;
    const result = { ...existing, ...updateRest };
    if (updatedData) {
      result.data = { ...existingData, ...updatedData };
    }
    if (updatedCustomFields) {
      result.customFields = this.mergeCustomFields(existingCustomFields, updatedCustomFields);
    }
    return result;
  }

  updateEntries = async (
    updates: ListEntryUpdate[],
    options?: { listId?: string; pessimistic?: boolean },
  ): Promise<void> => {
    try {
      const listId = options?.listId || this.requireList().id;
      const pessimistic = options?.pessimistic || false;
      if (pessimistic) {
        const updatedEntries = await API.listEntries.update(listId, { entries: updates });
        const updatedEntriesMap = new Map(updatedEntries.map((entry) => [entry.id, entry]));
        const mergedEntries = (this.entries.get() || []).map(
          (entry) => updatedEntriesMap.get(entry.id) || entry,
        );
        this.entries.set(mergedEntries);
      } else {
        await this.optimisticUpdateEntries(updates, listId);
      }
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  private mergeCustomFields(existing: ListCustomFieldValue[], updates: ListEntryCustomFieldSpec[]) {
    const newFields = updates
      .filter((update) => !existing.some((field) => field.fieldId === update.fieldId))
      .map((update) => update as ListCustomFieldValue);

    const mergedFields = existing.reduce<ListCustomFieldValue[]>((acc, field) => {
      const updateField = updates.find((f) => f.fieldId === field.fieldId);
      if (!updateField || updateField.data === "delete") {
        return [...acc, field];
      }
      return [...acc, { ...field, data: updateField.data }];
    }, []);

    return [...mergedFields, ...newFields];
  }

  addEntries = async (
    entrySpecs: ListEntrySpec[],
    options?: { listId?: string },
  ): Promise<ListEntryDetails[] | null> => {
    this.validateEntries(entrySpecs);
    try {
      const listId = options?.listId || this.requireList().id;
      const newEntries = await API.listEntries.create(listId, { entrySpecs });
      const totalEntries = this.totalEntries.get();
      this.entries.set(
        [...(this.entries.get() || []), ...newEntries].sort((a, b) => a.order - b.order),
      );
      this.totalEntries.set(totalEntries + newEntries.length);

      eventTracker.capture(newEntries.length > 1 ? "list-add-entry-bulk" : "list-add-entry", {
        listId,
        entries: newEntries,
      });
      return newEntries;
    } catch (e: unknown) {
      this.reportError(e);
      return null;
    }
  };

  statefulDeleteEntries = async (entryIds: string[]) => {
    try {
      const listId = this.requireList().id;
      await this.deleteEntries(entryIds, listId);
      const totalEntries = this.totalEntries.get();
      const entries = this.entries.get() || [];
      const prevEntryCount = entries.length;
      const remainingEntries = entries.filter((entry) => !entryIds.includes(entry.id));
      this.entries.set(remainingEntries);
      this.totalEntries.set(totalEntries - (prevEntryCount - remainingEntries.length));
    } catch (e: unknown) {
      this.reportError(e, { entryIds });
    }
  };

  deleteEntries = async (entryIds: string[], listId: string) => {
    try {
      await API.listEntries.delete(listId, entryIds);
      eventTracker.capture(entryIds.length > 1 ? "list-delete-entry-bulk" : "list-delete-entry", {
        listId,
        entryIds,
      });
    } catch (e: unknown) {
      this.reportError(e, { listId, entryIds });
    }
  };

  createView = async (options?: { publish?: boolean }) => {
    try {
      const { publish } = options || {};
      const list = this.requireList();
      const existingView = this.view.get();
      if (existingView) {
        logger.error("View already exists");
        throw new Error("View already exists");
      }
      const view = await API.listViews.create(list, {
        displayMode: "cards",
        ...(publish && { publishedAt: new Date() }),
      });
      this.view.set(view);
      const publicUrl = ListRoutes.view(view, { listName: list.name, prefix: location.origin });
      await navigator.clipboard.writeText(publicUrl);
      toast.success("Link copied to clipboard!");
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  updateView = async (data: UpdateViewParams) => {
    try {
      const list = this.requireList();
      const existingView = this.requireView();

      const optimisticView = { ...existingView, ...data };
      this.view.set(optimisticView);

      await API.listViews.update(list.id, existingView.id, data);
    } catch (e: unknown) {
      this.reportError(e);
    }
  };

  inviteListUser = async (email: string, accessLevel: "owner" | "member" | "delete") => {
    try {
      const list = this.requireList();
      const currentInvites = this.invites.get() || [];
      const existingInvite = currentInvites.find((invite) => invite.email === email);

      if (accessLevel === "delete") {
        if (existingInvite) {
          await this.deleteInvite(existingInvite.id);
        }
      } else {
        if (existingInvite) {
          // Optimistically update existing invite's access level
          const updatedInvites = currentInvites.map((invite) =>
            invite.id === existingInvite.id ? { ...invite, accessLevel } : invite,
          );
          this.invites.set(updatedInvites);
        }

        await API.listInvites.create(list, { email, accessLevel });
        await this.loadListMemberships();
      }
    } catch (e: unknown) {
      this.reportError(e, { email });
      // Revert on error
      await this.loadListMemberships();
    }
  };

  changeListUserAccess = async (listUserId: string, accessLevel: "owner" | "member" | "delete") => {
    const list = this.requireList();
    const currentUsers = this.listUsers.get() || [];

    try {
      if (accessLevel === "delete") {
        // Optimistically remove the user
        this.listUsers.set(currentUsers.filter((user) => user.id !== listUserId));
        await API.listUsers.delete(list.id, listUserId);
      } else {
        // Optimistically update the user's role
        const updatedUsers = currentUsers.map((user) => {
          if (user.id === listUserId) {
            return { ...user, role: accessLevel };
          }
          return user;
        });
        this.listUsers.set(updatedUsers);
        await API.listUsers.update(list.id, listUserId, { role: accessLevel });
      }
      await this.loadListMemberships();
    } catch (e: unknown) {
      this.reportError(e, { listUserId, accessLevel });
      // Revert on error
      await this.loadListMemberships();
    }
  };

  deleteInvite = async (inviteId: string) => {
    const list = this.requireList();
    // Optimistically remove the invite from the list
    const currentInvites = this.invites.get() || [];
    const updatedInvites = currentInvites.filter((invite) => invite.id !== inviteId);
    this.invites.set(updatedInvites);
    await API.listInvites.delete(list.id, inviteId);
    await this.loadInvites();
  };

  private subscribeToEntity = async (entityId: string) => {
    if (!uiStore.user.get()) return;
    const realtime = await uiStore.getConnectedRealtime();
    const channel = realtime.channels.get("entity:" + entityId);

    if (!channel) {
      logger.debug("no channel for entity", entityId);
      return;
    }
    logger.debug("subscribing to entity", entityId);
    this.subscriptions[entityId] = channel;

    channel.subscribe("progress", (msg) => {
      const progress = msg.data as EntityProgressUpdate;
      logger.debug("entity progress", entityId, progress);
      this.entitiesInProgress.setKey(entityId, progress);
    });

    channel.subscribe("finished", (msg) => {
      logger.debug("entity finished", entityId, msg);
      this.entitiesInProgress.setKey(entityId, undefined);
      const channel = this.subscriptions[entityId];
      if (channel) {
        try {
          channel.unsubscribe();
        } catch (e) {
          logger.error("error unsubscribing from entity", entityId, e);
        }
        Reflect.deleteProperty(this.subscriptions, entityId);
      }
      const entries = this.entries.get() || [];
      const updatedEntries = entries.map((entry) => {
        if (entry.entityId === entityId) {
          return { ...entry, entity: { ...entry.entity, generatedAt: new Date() } };
        }
        return entry;
      });
      this.entries.set(updatedEntries as ListEntryDetails[]);
    });
  };

  cleanup = () => {
    this.cleanupListSubscription();
  };
}

export const listStore = new ListStore();

listStore.list.listen((listDetails) => {
  listStore.onListChange(listDetails);
});

listStore.entries.listen((entries) => {
  listStore.onEntriesChange(entries);
});

listStore.listOverviews.listen((overviews) => {
  listStore.onOverviewsChange(overviews);
});

listStore.view.listen((view) => {
  listStore.onViewChange(view);
});

listStore.snapshots.listen((snapshots) => {
  listStore.onSnapshotsChange(snapshots);
});

listStore.entriesWithQueuedEntities.listen((entries) => {
  listStore.onEntriesWithQueuedEntitiesChange(entries);
});

export default listStore;
