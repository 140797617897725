import Select from "@/components/inputs/Select";
import Button from "@/components/ui/Button";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import errorTracker from "@/lib/errorTracker";
import eventTracker from "@/lib/trackers/eventTracker";
import { prettyError } from "@/lib/utils";
import listStore from "@/stores/listStore";
import { uiStore } from "@/stores/uiStore";
import { ListUserRole } from "@/types";
import { EntityType } from "@/types/enums";
import { useStore } from "@nanostores/react";
import { capitalize } from "lodash";
import { useState } from "react";

interface ListPerson {
  name?: string;
  email: string;
  imageUrl?: string;
  accessLevel: ListUserRole;
  listUserId?: string;
  isPending?: boolean;
}

const InviteListUsers = () => {
  const list = useStore(listStore.list);
  const invites = useStore(listStore.invites);
  const listUsers = useStore(listStore.listUsers);
  const user = useStore(uiStore.user);
  const currentUserRole = useStore(listStore.listRole);

  const [inviteEmail, setInviteEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const isOwner = currentUserRole === "owner";
  const pendingInvites = invites?.filter((invite) => !invite.joinedUserId);
  const canSubmit = inviteEmail.includes("@") && inviteEmail.includes(".");

  const handleInvite = async (accessLevel: ListUserRole) => {
    setLoading(true);
    try {
      await listStore.inviteListUser(inviteEmail, accessLevel);
      eventTracker.capture("list-invite-sent", {
        email: inviteEmail,
        listId: list?.id,
        accessLevel,
      });
      setInviteEmail("");
    } catch (e) {
      errorTracker.sendError(prettyError(e));
    } finally {
      setLoading(false);
    }
  };

  const handlePermissionsChange = async ({
    inviteEmail,
    listUserId,
    accessLevel,
  }: {
    inviteEmail?: string;
    listUserId?: string;
    accessLevel: ListUserRole;
  }) => {
    if (listUserId) {
      await listStore.changeListUserAccess(listUserId, accessLevel);
    } else if (inviteEmail) {
      await listStore.inviteListUser(inviteEmail, accessLevel);
    }
  };

  const people: ListPerson[] = [
    ...(listUsers?.map((listUser) => ({
      name: listUser.user.name || undefined,
      imageUrl: listUser.user.image || undefined,
      email: listUser.user.email || "",
      accessLevel: listUser.role as ListUserRole,
      listUserId: listUser.id,
    })) ?? []),
    ...(pendingInvites?.map((invite) => ({
      name: invite.joinedUser?.name || undefined,
      imageUrl: invite.joinedUser?.image || undefined,
      email: invite.email,
      accessLevel: invite.accessLevel as ListUserRole,
      isPending: true,
    })) ?? []),
  ];

  return (
    <div className="flex flex-col gap-4 w-full">
      {isOwner && (
        <InviteForm
          inviteEmail={inviteEmail}
          setInviteEmail={setInviteEmail}
          handleInvite={handleInvite}
          canSubmit={canSubmit}
          loading={loading}
          listName={list?.name}
        />
      )}

      <div className="flex flex-col gap-2">
        <p className={!isOwner ? "text-2xl mb-2" : ""}>People with access</p>
        {people?.map((person) => (
          <ListUserRow
            key={person.email}
            person={person}
            isUser={user?.email === person.email}
            isOwner={isOwner}
            onChange={(accessLevel) =>
              handlePermissionsChange({
                inviteEmail: person.email,
                listUserId: person.listUserId,
                accessLevel,
              })
            }
          />
        ))}
      </div>
    </div>
  );
};

interface InviteFormProps {
  inviteEmail: string;
  setInviteEmail: (email: string) => void;
  handleInvite: (accessLevel: ListUserRole) => Promise<void>;
  canSubmit: boolean;
  loading: boolean;
  listName?: string;
}

const InviteForm = ({
  inviteEmail,
  setInviteEmail,
  handleInvite,
  canSubmit,
  loading,
  listName,
}: InviteFormProps) => (
  <>
    <h1 className="text-2xl">Share &quot;{listName}&quot;</h1>
    <div className="flex gap-2 w-full">
      <input
        type="text"
        placeholder="Email"
        value={inviteEmail}
        onChange={(e) => setInviteEmail(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && canSubmit && !loading) {
            void handleInvite("member");
          }
        }}
        className="flex-1 border border-gray-200 rounded-md p-2"
      />
      <Button onClick={() => handleInvite("member")} disabled={!canSubmit || loading}>
        Share
      </Button>
    </div>
  </>
);

interface ListUserRowProps {
  onChange?: (accessLevel: ListUserRole) => void;
  isUser?: boolean;
  isOwner?: boolean;
  person?: ListPerson;
}

const ListUserRow = ({ onChange, isUser, isOwner, person }: ListUserRowProps) => {
  const { name, email, accessLevel, imageUrl, isPending } = person || {};

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center gap-2">
        <EntityIconWithPlaceholder
          entity={{
            type: EntityType.Person,
            imageUrl,
            name: name || email,
          }}
          className="w-12 h-12"
        />
        <div className="flex flex-col">
          {isPending ?
            <>
              <p className="font-medium text-md">{email}</p>
              <p
                className="text-13pt text-yellow-500 w-min"
                data-tooltip-content="User has been invited to Distill"
                data-tooltip-id="tooltip"
              >
                Pending
              </p>
            </>
          : <>
              <p className="font-medium text-md">
                {name} {isUser ? "(You)" : ""}
              </p>
              <p className="text-13pt text-gray-500">{email}</p>
            </>
          }
        </div>
      </div>
      {isOwner && !isUser ?
        <Select
          options={[
            { value: "owner", label: "Owner" },
            { value: "member", label: "Member" },
            { value: "delete", label: "Revoke Access", className: "text-red-500" },
          ]}
          dropdownClassName="right-0"
          className="cursor-pointer"
          value={accessLevel}
          onChange={(e) => onChange?.(e as ListUserRole)}
        />
      : capitalize(accessLevel)}
    </div>
  );
};

export default InviteListUsers;
