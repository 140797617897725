/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  CreateListParams,
  ListEntriesResult,
  ListEntrySnapshotsResult,
  ListEntryUpdate,
  ListOverviewResult,
  ResolveListEntriesParams,
  UpdateListParams,
} from "@/lists";
import {
  ListDetails,
  ListEntryDetails,
  ListEntryResolution,
  ListEntrySpec,
  ListView,
  PagingOptions,
  SuccessResponse,
} from "@/types";

import { ResourceWithParent } from "@/client/resource";
import { ListInvite, ListUser, User } from "@prisma/client";
import { Connector, ItemsResponse } from "./apiTypes";

export type CreateListData = CreateListParams["data"];
export type UpdateListData = UpdateListParams["data"];
export type ResolveListEntriesData = Omit<ResolveListEntriesParams["data"], "listId">;

export class ListsResource {
  private readonly name: string;
  constructor(
    public conn: Connector,
    options?: { name?: string },
  ) {
    this.name = options?.name || "lists";
  }

  async list(params: PagingOptions): Promise<ListOverviewResult> {
    const response = await this.conn.axios.get(`/${this.name}`, {
      params,
    });
    return response.data;
  }

  async create(data: CreateListData): Promise<ListDetails> {
    const response = await this.conn.axios.post(`/${this.name}`, data);
    return response.data;
  }

  async get(id: string): Promise<ListDetails> {
    const response = await this.conn.axios.get(`/${this.name}/${id}`);
    return response.data;
  }

  async update(id: string, data: UpdateListData): Promise<ListDetails> {
    const response = await this.conn.axios.put(`/${this.name}/${id}`, data);
    return response.data;
  }

  async delete(id: string): Promise<void> {
    await this.conn.axios.delete(`/${this.name}/${id}`);
  }
}

export class ListEntriesResource {
  private readonly parentName: string;
  private readonly name: string;

  constructor(
    public conn: Connector,
    options?: { parentName?: string; name?: string },
  ) {
    this.parentName = options?.parentName || "lists";
    this.name = options?.name || "entries";
  }

  async list(
    listId: string,
    params: PagingOptions & { entityId?: string },
  ): Promise<ListEntriesResult> {
    const response = await this.conn.axios.get(`/${this.parentName}/${listId}/${this.name}`, {
      params,
    });
    return response.data;
  }

  async create(listId: string, data: { entrySpecs: ListEntrySpec[] }): Promise<ListEntryDetails[]> {
    const response = await this.conn.axios.post(`/${this.parentName}/${listId}/${this.name}`, data);
    return response.data;
  }

  async update(listId: string, data: { entries: ListEntryUpdate[] }): Promise<ListEntryDetails[]> {
    const response = await this.conn.axios.put(`/${this.parentName}/${listId}/${this.name}`, data);
    return response.data;
  }

  async delete(listId: string, itemIds: string[]): Promise<Date> {
    const response = await this.conn.axios.delete(`/${this.parentName}/${listId}/${this.name}`, {
      params: { itemIds },
    });
    return response.data;
  }

  async resolve(listId: string, data: ResolveListEntriesData): Promise<ListEntryResolution[]> {
    const response = await this.conn.axios.post<ListEntryResolution[]>(
      `/lists/${listId}/resolve`,
      data,
    );
    return response.data;
  }
}

export class ListViewsResource extends ResourceWithParent<ListDetails, ListView> {
  constructor(conn: Connector) {
    super(conn, "listId", "listViews");
  }

  async snapshots(
    viewId: string,
    params: PagingOptions & { listId: string },
  ): Promise<ListEntrySnapshotsResult> {
    const response = await this.conn.axios.get(`/${this.name}/${viewId}/snapshots`, {
      params,
    });
    return response.data;
  }
}

export class ListInvitesResource extends ResourceWithParent<ListDetails, ListInvite> {
  constructor(conn: Connector) {
    super(conn, "listId", "lists");
  }

  async list(
    parent: ListDetails,
  ): Promise<ItemsResponse<ListInvite & { joinedUser: User | null }>> {
    const response = await this.conn.axios.get(`/${this.name}/${parent.id}/invites`);
    return response.data;
  }

  async create(
    parent: ListDetails,
    data: { email: string; accessLevel: "owner" | "member" },
  ): Promise<ListInvite> {
    const response = await this.conn.axios.post(`/${this.name}/${parent.id}/invites`, data);
    return response.data;
  }

  async delete(parentId: string, inviteId: string): Promise<SuccessResponse> {
    const response = await this.conn.axios.delete(`/${this.name}/${parentId}/invites`, {
      params: { inviteId },
    });
    return response.data;
  }
}

export class ListUsersResource extends ResourceWithParent<ListDetails, ListUser> {
  constructor(conn: Connector) {
    super(conn, "listId", "lists");
  }

  async list(parent: ListDetails): Promise<ItemsResponse<ListUser & { user: User }>> {
    const response = await this.conn.axios.get(`/${this.name}/${parent.id}/listUsers`);
    return response.data;
  }

  async delete(parentId: string, listUserId: string): Promise<SuccessResponse> {
    const response = await this.conn.axios.delete(`/${this.name}/${parentId}/listUsers`, {
      params: { listUserId },
    });
    return response.data;
  }

  async update(
    parentId: string,
    id: string,
    item: { role: "owner" | "member" },
  ): Promise<ListUser & { user: User }> {
    const data = {
      listUserId: id,
      ...item,
    };
    const response = await this.conn.axios.put(`/${this.name}/${parentId}/listUsers`, {
      data,
    });
    return response.data;
  }
}
