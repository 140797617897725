import moment from "moment";

/* eslint-disable @next/next/no-img-element */
import ProfileBubble from "@/components/sections/ProfileBubble";
import ExpandoList from "@/components/ui/ExpandoList";
import { favicon, smartTruncate, unescapeHtmlCharacterEntities } from "@/lib/utils";
import { useEntityStore } from "@/stores/entityStore";
import { ProfilePageSection } from "@/types";
import { useStore } from "@nanostores/react";

export default function MediaCoverage() {
  const entityStore = useEntityStore();
  const snapshot = useStore(entityStore.snapshot);
  const authoredMedia = useStore(entityStore.authoredMedia);
  const authoredMediaUrls = new Set(authoredMedia.map((m) => m.url));

  const mediaArticles =
    snapshot?.references
      ?.filter((r) => snapshot.mediaLinkIds.includes(r.id))
      .filter((r) => !authoredMediaUrls.has(r.url)) || [];

  if (!mediaArticles.length) return null;

  const sortedMediaList = mediaArticles.toSorted((a, b) => {
    if (a.datePublished && b.datePublished) return b.datePublished.localeCompare(a.datePublished);
    return 0;
  });

  return (
    <ProfileBubble title="Media Coverage" section={ProfilePageSection.MediaCoverage}>
      <div role="list" className="flex flex-col gap-4">
        <ExpandoList
          items={sortedMediaList}
          renderItem={(s, idx) => (
            <div key={idx}>
              <a
                href={s.url}
                target="_blank"
                className="flex space-x-1 sm:space-x-3"
                rel="noreferrer"
              >
                <img
                  src={favicon({
                    url: s.url,
                    favicon: s.favicon,
                  })}
                  className="w-8 h-8 mt-1 rounded-md border border-gray-100"
                  alt="favicon"
                  onError={(e) => ((e.target as HTMLImageElement).style.display = "none")}
                />

                <div>
                  <div className="font-semibold line-clamp-1">{s.title}</div>
                  <div className="text-gray-400 text-sm">
                    {s.datePublished && moment(s.datePublished).format("ll")}
                    {s.author &&
                      ` by ${smartTruncate(String(unescapeHtmlCharacterEntities(s.author)), 100)}`}
                  </div>
                </div>
              </a>
            </div>
          )}
        />
      </div>
    </ProfileBubble>
  );
}
