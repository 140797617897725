import useSubmitButton from "@/components/hooks/useSubmitButton";
import ComboBox, {
  ComboBoxItemType,
  makeComboBoxItemsFromEnum,
} from "@/components/inputs/ComboBox";
import TextArea from "@/components/inputs/TextArea";
import TextField from "@/components/inputs/TextField";
import WithLabel from "@/components/inputs/WithLabel";
import Button, { ButtonVariant } from "@/components/ui/Button";
import { useErrorTracker } from "@/hooks/useErrorTracker";
import { prettyError } from "@/lib/miscUtils";
import { useEntityStore } from "@/stores/entityStore";
import {
  BaseRelationshipData,
  EmploymentType,
  PersonCompanyRelationship,
  RelationshipDataEducatedAt,
  RelationshipDataInvestedIn,
  RelationshipDataVolunteeredAt,
  RelationshipDataWorkedAt,
  RelationshipWithEntity,
} from "@/types";
import { useStore } from "@nanostores/react";
import { formatISO } from "date-fns";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";

interface Props {
  relationship: Partial<RelationshipWithEntity> & { type: string };
  onFinishEdit?: () => void;
}

const UNSPECIFIED = "(Unspecified)";

export default function EditExperienceRow({ relationship, onFinishEdit }: Props) {
  const entityStore = useEntityStore();
  const errorTracker = useErrorTracker();
  const [error, setError] = useState<string>();
  const { SubmitButton, setSubmitting } = useSubmitButton();
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const baseData = relationship?.data as BaseRelationshipData;
  const workData = relationship?.data as RelationshipDataWorkedAt;
  const educationData = relationship?.data as RelationshipDataEducatedAt;
  const investmentData = relationship?.data as RelationshipDataInvestedIn;
  const volunteeringData = relationship?.data as RelationshipDataVolunteeredAt;
  const relationships = useStore(entityStore.relationships);

  const isCurrent =
    !!relationship.id && (relationship.endedDate === "Present" || !relationship.endedDate);
  const currentRoles = relationships.filter((r) => {
    return (r.endedDate === "Present" || !r.endedDate) && r.type === relationship.type;
  });
  const maxRelationshipOrder = Math.max(
    ...relationships
      .filter((r) => r.type === relationship.type)
      .map((r) => (r.data as BaseRelationshipData)?.order || 0),
  );
  const allCurrentRelationshipsHaveOrder = currentRoles.every(
    (r) => (r.data as BaseRelationshipData)?.order !== undefined,
  );
  // If not all relationships have order, then we allow marking any of them
  // as primary. This will rearrange them and set order to all of them.
  const relationshipIsPrimary =
    isCurrent &&
    allCurrentRelationshipsHaveOrder &&
    (relationship.data as BaseRelationshipData)?.order === maxRelationshipOrder;

  useEffect(() => {
    try {
      setSelectedStartDate(
        relationship?.startedDate ? moment(relationship?.startedDate).toDate() : null,
      );
    } catch (e) {
      errorTracker.sendError(e, {
        relationshipId: relationship?.id,
      });
    }
    try {
      setSelectedEndDate(
        relationship?.endedDate && relationship?.endedDate !== "Present" ?
          moment(relationship?.endedDate).toDate()
        : null,
      );
    } catch (e) {
      errorTracker.sendError(e, {
        relationshipId: relationship?.id,
      });
      toast.error(prettyError(e));
    }
  }, [relationship, errorTracker]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    const title = formData.get("title") as string | null;
    let type = formData.get("type") as string | null;
    if (type === UNSPECIFIED) type = null;
    const location = formData.get("location") as string | null;
    const startDate =
      selectedStartDate ? formatISO(selectedStartDate, { representation: "date" }) : null;
    const endDate = selectedEndDate ? formatISO(selectedEndDate, { representation: "date" }) : null;
    const description = formData.get("description") as string | null;
    const degree = formData.get("degree") as string | null;
    const role = formData.get("role") as string | null;

    try {
      setError(undefined);
      if (relationship?.id) {
        // Editing case
        await entityStore.updateRelationship({
          ...(relationship as { id: string }),
          startedDate: startDate,
          endedDate: endDate,
          data: {
            ...(relationship.data as object),
            location,
            description,
            degree,
            title,
            type,
            role,
          },
        });
      } else {
        // Adding case
        await entityStore.createRelationship({
          // Some fields need to be set by the parent component. The fields
          // responsible for the relationship type, as well as where the
          // relationship is pointing to.
          ...relationship,
          startedDate: startDate,
          endedDate: endDate,
          data: {
            location,
            description,
            title,
            degree,
            type,
            role,
          },
        });
      }
      onFinishEdit?.();
    } catch (e) {
      setError(prettyError(e));
    } finally {
      setSubmitting(false);
    }
  };

  const employmentTypes: ComboBoxItemType[] = [
    { id: "", name: UNSPECIFIED },
    ...makeComboBoxItemsFromEnum(EmploymentType),
  ];

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <hr className="w-full mb-2" />
        {isCurrent && !relationshipIsPrimary && currentRoles.length > 1 && (
          <div className="flex flex-row mb-2 items-center">
            <div className="mr-2">
              <Button
                variant={ButtonVariant.Primary}
                onClick={async () => {
                  if (!relationship.id) return;
                  await entityStore.updateRelationship({
                    ...relationship,
                    id: relationship.id,
                    data: {
                      ...(relationship.data as object),
                      order: maxRelationshipOrder + 2,
                    },
                  });
                  if (!allCurrentRelationshipsHaveOrder) {
                    // If not all current relationships have an order, setting
                    // it only for the relationship being edited might have
                    // unexpected results (sorting between relationships might
                    // not work in case order is not defined). That's why
                    // we need to set the order for all current relationships.
                    for (const role of currentRoles) {
                      if (role.id === relationship.id) continue;
                      await entityStore.updateRelationship({
                        ...role,
                        id: role.id,
                        data: {
                          ...(role.data as object),
                          order:
                            (role.data as BaseRelationshipData)?.order || maxRelationshipOrder + 1,
                        },
                      });
                    }
                  }
                }}
              >
                Mark as primary
              </Button>
            </div>
            <div className="text-sm text-gray-500">
              Highlight this role and company in your profile
            </div>
          </div>
        )}
        <div className="grid grid-cols-3 gap-4">
          {relationship.type === PersonCompanyRelationship.WorkedAt && (
            <WithLabel label={"Title"}>
              <TextField name="title" placeholder="Title" defaultValue={workData?.title} />
            </WithLabel>
          )}
          {relationship.type === PersonCompanyRelationship.VolunteeredAt && (
            <WithLabel label={"Role"}>
              <TextField name="role" placeholder="Role" defaultValue={volunteeringData?.role} />
            </WithLabel>
          )}
          {relationship.type === PersonCompanyRelationship.EducatedAt && (
            <WithLabel label={"Deegree"}>
              <TextField name="degree" placeholder="Degree" defaultValue={educationData?.degree} />
            </WithLabel>
          )}
          {relationship.type === PersonCompanyRelationship.WorkedAt && (
            <WithLabel label={"Employment type"}>
              <ComboBox
                name="type"
                items={employmentTypes}
                value={
                  relationship?.id ?
                    employmentTypes.find((x) => x.name === workData?.type) || employmentTypes[0]
                  : undefined
                }
                onSelect={async (typeOrArray) => {
                  const type = Array.isArray(typeOrArray) ? typeOrArray[0] : typeOrArray;
                  try {
                    if (relationship?.id) {
                      await entityStore.updateRelationship({
                        ...(relationship as { id: string }),
                        data: {
                          ...(relationship.data as object),
                          type: type.id === "" ? "" : type.name,
                        },
                      });
                    }
                  } catch (e) {
                    setError(prettyError(e));
                  }
                }}
              />
            </WithLabel>
          )}
          {(relationship.type === PersonCompanyRelationship.WorkedAt ||
            relationship.type === PersonCompanyRelationship.EducatedAt) && (
            <WithLabel label={"Location"}>
              <TextField
                name="location"
                type="text"
                placeholder="Location"
                defaultValue={workData?.location}
              />
            </WithLabel>
          )}

          <WithLabel label={"Start date"}>
            <div className="w-full flex flex-col">
              <DatePicker
                className="border-gray-300 w-full rounded-md text-sm text-black p-2 leading-6"
                selected={selectedStartDate}
                showMonthYearPicker
                dateFormat={"yyyy-MM"}
                maxDate={new Date()}
                onChange={(date) => {
                  setSelectedStartDate(date);
                }}
              />
            </div>
          </WithLabel>
          <WithLabel label={"End date (empty if ongoing)"}>
            <div className="w-full flex flex-col">
              <DatePicker
                className="border-gray-300 w-full rounded-md text-sm text-black p-2 leading-6"
                selected={selectedEndDate}
                showMonthYearPicker
                dateFormat={"yyyy-MM"}
                minDate={selectedStartDate || undefined}
                maxDate={new Date()}
                onChange={(date) => {
                  setSelectedEndDate(date);
                }}
              />
            </div>
          </WithLabel>
        </div>
        <div className="mt-2">
          <WithLabel label={"Description"}>
            <TextArea rows={6} name="description" defaultValue={baseData?.description}></TextArea>
          </WithLabel>
        </div>
        {error && <div className="text-red-500 mt-2">{error}</div>}
        <div className="flex my-2">
          <div className="grow"></div>
          <Button variant={ButtonVariant.Secondary} onClick={() => onFinishEdit?.()}>
            Cancel
          </Button>
          <SubmitButton className="ml-2">Save</SubmitButton>
        </div>
        <hr className="w-full" />
      </form>
    </div>
  );
}
