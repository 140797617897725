import { atom, computed } from "nanostores";
import { useContext } from "react";

import { logger } from "@/lib/logger";
import { uiStore } from "@/stores/uiStore";
import { ChatRole, EntityUIType, MessageAttachments, OpenAIChatMessage, UIMessage } from "@/types";

import type { Types as Ably } from "ably";

export enum MessageStoreType {
  Search = "search",
  Entity = "entity",
}

/** used to render messages. this is used in multiple places */
export class MessageStore {
  type = atom<MessageStoreType>(MessageStoreType.Search);

  // --- fields

  // messages, with newest at the front
  messages = atom<UIMessage[]>([]);

  isEmpty = computed([this.messages], (messages) => messages.length === 0);

  inProgress = atom<boolean>(false);

  partialMessage = atom<string | undefined>();

  currentMessage = atom<UIMessage | null>(null);

  error = atom<string | undefined>();

  // --- actions

  subscription: { id: string; channel: Ably.RealtimeChannelCallbacks } | undefined;

  onSendMessage: ((store: MessageStore, content: string) => Promise<void>) | undefined;
  onUpdateMessage:
    | ((store: MessageStore, message: UIMessage, updates: Partial<UIMessage>) => Promise<void>)
    | undefined;
  onDeleteMessage: ((store: MessageStore, message: UIMessage) => Promise<void>) | undefined;

  init = (type: MessageStoreType) => {
    this.type.set(type);
    this.messages.set([]);
    this.error.set(undefined);
    this.partialMessage.set(undefined);
    this.currentMessage.set(null);
    this.inProgress.set(false);
    this.toDelete = [];

    if (this.subscription) {
      this.subscription.channel.unsubscribe();
      this.subscription = undefined;
    }
  };

  private interruptCallback = () => {};
  interrupt = () => {
    this.interruptCallback();
    this.inProgress.set(false);
    this.partialMessage.set(undefined);
  };

  setCurrentMessageContent = (content: string) => {
    if (!content) {
      this.currentMessage.set(null);
      return;
    }
    let currentMessage = this.currentMessage.get();
    if (!currentMessage) {
      currentMessage = {
        id: new Date().toISOString(),
        content,
        role: ChatRole.User,
        userId: uiStore.user.get()?.id,
        createdAt: new Date(),
      };
    }
    this.currentMessage.set({
      ...currentMessage,
      content,
    });
  };

  sendCurrentMessage = async (force?: boolean) => {
    if (this.inProgress.get()) {
      if (force) {
        this.interrupt();
      } else {
        return;
      }
    }

    const currentMessage = this.currentMessage.get();
    if (!currentMessage || !currentMessage.content) return;
    logger.info("sending message", currentMessage.content);
    this.currentMessage.set(null);
    this.inProgress.set(true);
    this.pushMessage(currentMessage);

    const chatPromise =
      this.onSendMessage?.(this, currentMessage.content || "") || Promise.resolve();

    if (this.toDelete.length) {
      const toDelete = this.toDelete;
      this.toDelete = [];
      toDelete.forEach((m) => {
        void this.deleteMessage(m);
      });
    }

    await chatPromise;
    this.inProgress.set(false);
  };

  lastMessage: UIMessage | undefined;
  lastUserMessage: UIMessage | undefined;

  getHistory = (messages: UIMessage[]) => {
    const history: OpenAIChatMessage[] = messages.map((m) => ({
      role: m.role == ChatRole.Assistant || m.role == ChatRole.User ? m.role : ChatRole.User,
      content: m.content || "",
      function_call: m.attachments?.function_call,
    }));

    let messageBudget = 2000;
    const truncated = history.reduce<OpenAIChatMessage[]>((acc, message) => {
      if (messageBudget < 0) return acc;
      const encodedLength = JSON.stringify(message).length / 3;
      messageBudget -= encodedLength;
      // note that this reverses the order of the messages so oldest is first
      return [message, ...acc];
    }, []);

    return truncated;
  };

  pushMessage = (message: UIMessage | OpenAIChatMessage) => {
    const attachments: MessageAttachments | undefined =
      (message as UIMessage).attachments ||
      ((message as OpenAIChatMessage).function_call ?
        {
          function_call: (message as OpenAIChatMessage).function_call,
        }
      : undefined);

    const uiMessage: UIMessage = {
      createdAt: new Date(),
      userId: uiStore.user.get()?.id,
      attachments,
      ...message,
    };

    this.messages.set([uiMessage, ...this.messages.get()]);
    this.lastMessage = uiMessage;
    if (message.role == ChatRole.User) this.lastUserMessage = uiMessage;

    return uiMessage;
  };

  updateMessage = async (message: UIMessage, updates: Partial<UIMessage>) => {
    if (!message?.id) throw new Error("no message id to update");
    if (this.onUpdateMessage) await this.onUpdateMessage(this, message, updates);
  };

  toDelete: UIMessage[] = [];

  requestEdit = (message: UIMessage) => {
    this.popMessages(message);
    this.currentMessage.set(message);
  };

  popMessages = (target: UIMessage) => {
    const messages = this.messages.get();
    const index = messages.indexOf(target);
    if (index === -1) return [];
    const newMessages = messages.slice(index + 1);
    this.toDelete = messages.slice(0, index + 1);

    // don't update session until we have a new message
    this.messages.set(newMessages);
    return newMessages;
  };

  prependMessages = (messages: UIMessage[]) => {
    const sortedMessages = messages
      .filter((m): m is UIMessage & { createdAt: Date } => m.createdAt !== undefined)
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

    const currentMessages = this.messages.get();
    this.messages.set([...currentMessages, ...sortedMessages]);
  };

  deleteMessage = async (target: UIMessage) => {
    const messages = this.messages.get();
    this.messages.set(messages.filter((message) => message !== target));
    if (this.onDeleteMessage) await this.onDeleteMessage(this, target);
  };

  getLatestMessage(): UIMessage | undefined {
    return this.messages.get()[0];
  }
}

declare global {
  interface Window {
    messageStore: MessageStore;
  }
}

export const messageStore = new MessageStore();
if (typeof window !== "undefined") window.messageStore = messageStore;

// Hook to use within components to get the correct store instance
export const useMessageStore = () => {
  // TODO - make it per-id like entityStore
  return messageStore;
};
