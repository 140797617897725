// --- advanced types

import { EntityType } from "@/types/enums";

export type FieldCriteria = {
  field: QueryField | ""; //used for new filters
  name?: string;
  include?: string[];
  exclude?: string[];
  min?: number;
  max?: number;
  exact?: boolean;
};

export type CompoundCriteria = {
  OR: FieldCriteria[];
};

export type Criteria = CompoundCriteria | FieldCriteria;
export type FilterQuery = Criteria[];

export const isCompoundCriteria = (c: Criteria): c is CompoundCriteria => {
  return "OR" in c;
};

export const isCriteria = (c: Criteria): c is FieldCriteria => {
  return "field" in c;
};

export enum PersonQueryField {
  name = "name",
  tag = "tag",
  location = "location",
  age = "age",
  pronoun = "pronoun",
  currentCompanyName = "currentCompanyName",
  currentCompanyFulltext = "currentCompanyFulltext",
  currentTitle = "currentTitle",
  currentOrPreviousTitle = "currentOrPreviousTitle",
  currentOrPreviousCompanyName = "currentOrPreviousCompanyName",
  currentOrPreviousCompanyFulltext = "currentOrPreviousCompanyFulltext",
  schoolName = "schoolName",
  schoolFulltext = "schoolFulltext",
  investmentName = "investmentName",
  investmentFulltext = "investmentFulltext",
  degree = "degree",
  // highlights = "highlights",
  fulltext = "fulltext",
}

export enum CompanyQueryField {
  tag = "tag",
  industry = "industry",
  companyType = "companyType",
  employees = "employees",
  funding = "funding",
  founded = "founded",
  investmentRounds = "investmentRounds",
  headquarters = "headquarters",
  fulltext = "fulltext",
}

export type QueryField = keyof typeof PersonQueryField | keyof typeof CompanyQueryField;

export const QueryFieldLabels: Record<QueryField, string> = {
  [PersonQueryField.name]: "Name",
  [PersonQueryField.tag]: "Tag",
  [PersonQueryField.location]: "Location",
  [PersonQueryField.age]: "Age",
  [PersonQueryField.pronoun]: "Preferred Pronouns",
  [PersonQueryField.currentCompanyName]: "Current Company Name",
  [PersonQueryField.currentCompanyFulltext]: "Current Company (full-profile)",
  [PersonQueryField.currentTitle]: "Current Title",
  [PersonQueryField.currentOrPreviousTitle]: "Current or Previous Title",
  [PersonQueryField.currentOrPreviousCompanyName]: "Current or Previous Company Name",
  [PersonQueryField.currentOrPreviousCompanyFulltext]: "Current or Previous Company (full-profile)",
  [PersonQueryField.schoolName]: "School Name",
  [PersonQueryField.schoolFulltext]: "School (full-profile)",
  [PersonQueryField.degree]: "Degree",
  [PersonQueryField.investmentName]: "Invested in (name)",
  [PersonQueryField.investmentFulltext]: "Invested in (full-profile)",
  // [PersonQueryField.highlights]: "Highlights",
  [PersonQueryField.fulltext]: "Anywhere (full-profile)",
  [CompanyQueryField.industry]: "Industry",
  [CompanyQueryField.companyType]: "Company Type",
  [CompanyQueryField.employees]: "Employees",
  [CompanyQueryField.founded]: "Founding Year",
  [CompanyQueryField.headquarters]: "Headquarters",
  [CompanyQueryField.funding]: "Latest Funding Round",
  [CompanyQueryField.investmentRounds]: "Investment Rounds",
};

export const QueryFieldConversationalLabels: Record<QueryField, string> = {
  [PersonQueryField.name]: "have name similar to",
  [PersonQueryField.tag]: "are tagged with",
  [PersonQueryField.location]: "are currently located in",
  [PersonQueryField.age]: "are",
  [PersonQueryField.pronoun]: "use pronouns:",
  [PersonQueryField.currentCompanyName]: "are currently working at",
  [PersonQueryField.currentCompanyFulltext]: "are currently working at company related to",
  [PersonQueryField.currentTitle]: "currently holds the job title of",
  [PersonQueryField.currentOrPreviousTitle]: "currently or previously held the job title of",
  [PersonQueryField.currentOrPreviousCompanyName]: "currently or previously worked at",
  [PersonQueryField.currentOrPreviousCompanyFulltext]:
    "currently or previously worked at company related to",
  [PersonQueryField.schoolName]: "went to school at",
  [PersonQueryField.schoolFulltext]: "went to school at a school related to",
  [PersonQueryField.degree]: "have a degree in",
  [PersonQueryField.investmentName]: "invested in",
  [PersonQueryField.investmentFulltext]: "invested in a company related to",
  // [PersonQueryField.highlights]: "Highlights",
  [PersonQueryField.fulltext]: "are related to",
  [CompanyQueryField.industry]: "are in the industry of",
  [CompanyQueryField.companyType]: "are of type",
  [CompanyQueryField.employees]: "have number of employees",
  [CompanyQueryField.founded]: "were founded in",
  [CompanyQueryField.headquarters]: "are headquartered in",
  [CompanyQueryField.funding]: "have latest funding round of",
  [CompanyQueryField.investmentRounds]: "invest in round",
};

export type SmartSearchResponse = {
  peopleQuery?: FilterQuery;
  companiesQuery?: FilterQuery;
  message?: string;
};

export enum BuiltinStartingSet {
  Linkedin = "linkedin",
  Everything = "everything",
  Network = "network",
}

export const SmartSearchStartingSets = [
  { id: BuiltinStartingSet.Everything, name: "All of Distill" },
  { id: BuiltinStartingSet.Linkedin, name: "My Linkedin Connections (coming soon)" },
  { id: BuiltinStartingSet.Network, name: "My Network's Connections (coming soon)" },
];

export enum AdvancedSearchType {
  Investors = "investors",
  Other = "other",
}

export type SearchEntityType = "people" | "companies";

export type SearchRanking = {
  descriptionSimilarity?: string;
  investmentSimilarity?: string;
};

export type SearchQueryAPIResult = {
  id: string;
  name: string;
  type: EntityType;
  investments?: { id: string; name: string; score: number }[];
};

export type SearchQueryAPIResponse = {
  total: { value: number; relation: string };
  results: SearchQueryAPIResult[];
  searchId: string;
};

export type SearchExplainRequest = {
  entityId: string;
  type: AdvancedSearchType;
  query: FilterQuery;
  ranking?: SearchRanking;
};
