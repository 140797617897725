import { useSession } from "next-auth/react";
import { useRouter } from "next/compat/router";
import { useEffect } from "react";

import { loggerWithPrefix } from "@/lib/logger";
import { ServerProps } from "@/shared/serverProps";
import { listStore } from "@/stores/listStore";
import { uiStore } from "@/stores/uiStore";
import { FeatureFlag, UserWithMeta } from "@/types";
import { useStore } from "@nanostores/react";
import { useFeatureFlagEnabled, useFeatureFlagPayload } from "posthog-js/react";

const logger = loggerWithPrefix("useUIStore");

export const useDevTools = () => {
  return useStore(uiStore.isDev);
};

export function useLoggedOutUI() {
  useUI({});
}

export function useUI(props?: ServerProps) {
  const session = useSession();
  const router = useRouter();
  const maxSizeFlag = useFeatureFlagPayload(FeatureFlag.ListMaxSize);

  if (!props) {
    const dbUser = session.data?.dbUser as UserWithMeta | undefined;
    props = {
      userId: dbUser?.id,
      user: dbUser,
    };
  }

  useEffect(() => {
    uiStore.init(props);
  }, [props]);

  useEffect(() => {
    uiStore.router = router;
  }, [router]);

  useEffect(() => {
    if (props.user) {
      uiStore.setUser(props.user);
    } else if (session.data?.user) {
      uiStore.setUser({ ...session.data.user, meta: null } as UserWithMeta);
    } else {
      uiStore.setNoUser();
    }
  }, [session, props.user]);

  useEffect(() => {
    if (typeof maxSizeFlag === "number") {
      logger.debug("Setting max list size to", maxSizeFlag);
      listStore.maxSize.set(maxSizeFlag);
    }
  }, [maxSizeFlag]);

  return session;
}
