import { atom, computed, map, PreinitializedMapStore, ReadableAtom } from "nanostores";

import { toast, ToastOptions } from "react-toastify";

import API from "@/client/api";
import extensionScraper from "@/client/extensionScraper";
import { sanitizeLinks } from "@/crawler/sanitizeLinks";
import { DELAYED_REFRESH_WINDOW, HatchetWorkflow } from "@/hatchet/hatchetTypes";
import errorTracker from "@/lib/errorTracker";
import { loggerWithPrefix } from "@/lib/logger";
import eventTracker from "@/lib/trackers/eventTracker";
import { cleanBaseUrl, decodeUnicodeEscapes, prettyError, updateUnsubscribe } from "@/lib/utils";
import { feedbackStore } from "@/stores/feedbackStore";
import { uiStore } from "@/stores/uiStore";
import { classifyWorkPositions, findAttribute } from "@/stores/utils";
import {
  Attribute,
  AttributeType,
  CompanyFact,
  ConversationListing,
  CrawlResultSansBody,
  CrunchbaseData,
  Entity,
  EntityFact,
  EntityType,
  EntityUIType,
  EntityWithAttributes,
  FactSet,
  FactsPick,
  FactType,
  FactValueSet,
  FeatureFlag,
  GenericProfile,
  HighlightsFeedback,
  ImageDetails,
  LinkWithDescription,
  MutualConnectionsStatus,
  PersonCompanyRelationship,
  PersonFact,
  PipelineProgress,
  PipelineRunStatus,
  PositionClassification,
  PrivateAttributeMutualConnection,
  ProfilePageSection,
  QuestionType,
  RelationshipWithEntity,
  SnapshotHighlight,
  SocialAccount,
  SocialPosts,
  SocialServices,
  sortRelationships,
  SourceCat,
  SourceIsRight,
  WorkExperience,
} from "@/types";
import { replaceFaviconsWithLoader, resetFavicons } from "@/utils/domUtils";
import {
  buildStatsToShow,
  buildWorkAndEducationSections,
  entityIsUser,
  entityUrl,
} from "@/utils/entityUtils";
import { extractSocialInfo, isValidProfileUrl, SocialInfo } from "@/utils/socialUtils";
import {
  AuthoredMedia,
  EntityFilter,
  EntitySearchQuery,
  EntitySource,
  EntitySourceUpdate,
} from "@prisma/client";
import { formatDistanceToNow } from "date-fns";
import posthog, { PostHog } from "posthog-js";
import { Context, createContext, useContext } from "react";

const logger = loggerWithPrefix("[entityStore]");

export enum TabId {
  Overview = "overview",
  Analysis = "analysis",
  Sources = "sources",
  Search = "search",
}

export const entityTabs = [
  { id: TabId.Overview, name: "Overview" },
  { id: TabId.Analysis, name: "For You" },
  { id: TabId.Sources, name: "Sources" },
  { id: TabId.Search, name: "Search" },
];

type ProfileSectionMap = Partial<
  Record<ProfilePageSection, number | boolean | undefined | "loading">
>;

export type MutualConnectionsData = {
  data: PrivateAttributeMutualConnection;
  entity: EntityWithAttributes | null;
  resolved: boolean;
};

class EntityStore {
  entity = atom<Entity>({} as Entity);

  initialStatus = atom<PipelineRunStatus>(PipelineRunStatus.COMPLETED);

  entityLoading = atom<boolean>(false);

  attributes = atom<Attribute[]>([]);

  facts = map<FactsPick>({});

  sources = atom<EntitySource[]>([]);

  authoredMedia = atom<Partial<AuthoredMedia>[]>([]);

  sourceUpdates = atom<EntitySourceUpdate[]>([]);

  crawlResults = map<Record<string, CrawlResultSansBody>>({});

  highlights = atom<SnapshotHighlight[] | undefined>();

  highlightsFeedback = atom<HighlightsFeedback[]>([]);

  images = atom<ImageDetails[]>([]);

  debug = atom<boolean>(true);
  socials = atom<SocialAccount[]>([]);

  mutualConnections = map<Record<string, MutualConnectionsData>>({});

  mutualConnectionsStatus = atom<MutualConnectionsStatus>(MutualConnectionsStatus.Pending);

  latestProgress = atom<number>(0);

  starredSourcesUpdated = atom<boolean>(false);

  sourceCount = atom<number>(0);

  conversations = atom<ConversationListing[]>([]);

  selectedConversation = atom<ConversationListing | null>(null);

  uiTab = atom<TabId>(TabId.Overview);

  questionType = atom<QuestionType | undefined>(undefined);

  somethingWrongBanner = atom<boolean>(false);

  searchResults = atom<LinkWithDescription[] | "loading" | undefined>(undefined);

  searchState = atom<{ query: string; page: number } | undefined>(undefined);

  progress = atom<PipelineProgress | undefined>(undefined);

  relationships = atom<RelationshipWithEntity[]>([]);

  linkedEntities = map<Record<string, Entity>>({});

  validated = atom<"full" | "partial" | "bad" | "none" | undefined>(undefined);

  hideValidationPrompt = atom<boolean>(false);

  profileSections = map<ProfileSectionMap>({});

  currentSection = atom<ProfilePageSection | undefined>(undefined);

  mediaArticles = atom<CrawlResultSansBody[]>([]);

  workSections = map<Partial<Record<ProfilePageSection, WorkExperience[]>>>({});

  relationshipsLoading = atom<boolean>(false);

  relationshipSections = map<Partial<Record<ProfilePageSection, RelationshipWithEntity[]>>>({});

  numberedSources = atom<string[]>([]);

  fundingData = atom<CrunchbaseData | undefined>(undefined);

  searchFilter = atom<EntityFilter | undefined>(undefined);

  searchQuery = atom<EntitySearchQuery | undefined>(undefined);

  // Key is entity id, value is whether the entity is currently being updated, if entity id is in the queue regardless of value we can safely assume user has triggered an update previously
  updateEntityQueue = map<Record<string, { resolved: boolean }>>({});

  // since we map url -> entity we don't need to reset this between entities
  linkedCompanyMap = map<Record<string, EntityWithAttributes>>({});

  // used to track when a summary is being generated for a source
  summariesInProgress = atom<Record<string, boolean>>({});

  showFactEditModal = atom<
    | {
        type: FactType;
        currentValue?: string;
      }
    | undefined
  >();

  showHighlightsFeedbackModal = atom<
    | {
        previousFeedback: string[];
        onSubmit: (values: string) => void | Promise<void>;
      }
    | undefined
  >();

  isYou = computed([this.entity, uiStore.user], (entity, user) => {
    return !!user && entityIsUser(entity, user);
  });

  canEdit = computed([this.isYou, uiStore.isDev], (isYou, isDev) => {
    return isYou || isDev;
  });

  overviewRefreshTriggered = atom<string | undefined>(undefined);

  snapshot = atom<GenericProfile>({} as GenericProfile);

  // dev mode only
  aliases = atom<string[]>([]);

  private isLoadingMutualConnections = false;

  // --- actions

  load = async (
    entity: Entity,
    snapshot: GenericProfile,
    props: {
      attributes?: Attribute[];
      facts?: FactSet;
      sourcesOnly?: boolean;
      aliases?: string[];
      status?: PipelineRunStatus;
    },
  ) => {
    const { attributes, sourcesOnly, aliases, status } = props;
    logger.info("loaded snapshot", snapshot);

    this.loadingStarted();
    // if we're loading a new entity, we need to reset the store
    if (this.entity.get()?.id != entity.id) {
      this.highlights.set(undefined);
      this.sources.set([]);
      this.crawlResults.set({});
      this.starredSourcesUpdated.set(false);
      this.conversations.set([]);
      this.selectedConversation.set(null);
      this.uiTab.set(TabId.Overview);
      this.questionType.set(undefined);
      this.somethingWrongBanner.set(false);
      this.searchResults.set(undefined);
      this.searchState.set(undefined);
      this.sourceCount.set(0);
      this.relationships.set([]);
      this.mediaArticles.set([]);
      this.validated.set(undefined);
      this.numberedSources.set([]);
      this.progress.set(undefined);
      this.facts.set({});
      this.searchFilter.set(undefined);
      this.searchQuery.set(undefined);
      this.workSections.set({});
      this.relationshipSections.set({});
      this.linkedEntities.set({});
      this.mutualConnections.set({});
      this.mutualConnectionsStatus.set(MutualConnectionsStatus.Pending);
      this.isLoadingMutualConnections = false;
      this.authoredMedia.set([]);
      this.snapshot.set(snapshot);
    }

    this.entity.set(entity);
    this.attributes.set(attributes || []);
    this.snapshot.set(snapshot);
    this.aliases.set(aliases || []);
    this.initialStatus.set(status || PipelineRunStatus.COMPLETED);
    if (snapshot?.facts) this.facts.set(snapshot?.facts);
    if (snapshot?.highlights) this.highlights.set(snapshot?.highlights);

    void this.subscribe(entity.id);
    logger.info("loading entity", entity);

    if (entity.description?.includes("\\u")) {
      entity.description = decodeUnicodeEscapes(entity.description);
    }

    try {
      if (sourcesOnly) {
        await Promise.all([this.loadSources(entity), this.loadSearchFilter(entity)]);
      } else {
        this.initProfileSections(entity, attributes || []);
        // load authored media first so it can be used to filter out duplicates from media coverage
        await this.loadAuthoredMedia(entity);
        await Promise.all([
          this.loadSources(entity),
          this.loadRelationships(entity),
          this.loadMutualConnections(entity),
          this.loadHighlightsFeedback(entity),
          feedbackStore.load(entity),
        ]);
        if (uiStore.showDevTools()) {
          void this.loadSearchFilter(entity);
          void this.loadSearchQuery(entity);
        }
        this.loadFundingData(entity);
      }
    } catch (e) {
      toast.warn("Error loading page data");
      errorTracker.sendError(e, { source: "load-entity" });
    } finally {
      if (this.progress.get() === undefined) {
        // In case some update is in progress, we will reset the favicon
        // in subscribe method.
        this.loadingEnded();
      }
    }
  };

  loadingStarted = () => {
    this.entityLoading.set(true);
    replaceFaviconsWithLoader();
  };

  loadingEnded = () => {
    this.entityLoading.set(false);
    resetFavicons();
  };

  initProfileSections = (entity: Entity, attributes: Attribute[]) => {
    const linkedinProfile = findAttribute(attributes, AttributeType.LinkedinProfile)?.value;
    const workSections: Partial<Record<ProfilePageSection, WorkExperience[]>> = {
      // default state for works sections for section order purposes
      [ProfilePageSection.WorkHistory]: [],
      [ProfilePageSection.Investments]: [],
      [ProfilePageSection.OtherExperience]: [],
    };

    linkedinProfile?.experience?.forEach((workExperience) => {
      let section: ProfilePageSection = ProfilePageSection.WorkHistory;

      const positionClassifications = classifyWorkPositions(workExperience);
      const positionTypesMatch = positionClassifications.every(
        (p) => p === positionClassifications[0],
      );
      const overallClassification =
        (positionTypesMatch && positionClassifications[0]) || PositionClassification.Other;

      const classificationToSection: Record<PositionClassification, ProfilePageSection> = {
        [PositionClassification.Board]: ProfilePageSection.Investments,
        [PositionClassification.Investor]: ProfilePageSection.Investments,
        [PositionClassification.Volunteer]: ProfilePageSection.OtherExperience,
        [PositionClassification.Advisor]: ProfilePageSection.OtherExperience,
        [PositionClassification.Other]: ProfilePageSection.WorkHistory,
      };

      section = classificationToSection[overallClassification] || section;
      const list = (workSections[section] = workSections[section] || []);
      list.push(workExperience);
    });

    const facts = this.facts.get();
    const stats =
      entity.type == EntityType.Company ? buildStatsToShow(facts).length || undefined : undefined;

    const historicalHeadcount = facts[CompanyFact.HistoricalHeadcount]?.value;
    const hasHeadcount = historicalHeadcount && historicalHeadcount.length > 0;
    const hasEmployees =
      this.relationships.get().filter((r) => r.type === PersonCompanyRelationship.WorkedAt).length >
      0;
    const shouldShowPeople = hasHeadcount || hasEmployees;

    const sections: ProfileSectionMap = {
      [ProfilePageSection.Highlights]: true,
      [ProfilePageSection.About]: !!facts[EntityFact.About]?.value,
      [ProfilePageSection.MutualConnections]: Object.values(this.mutualConnections.get()).length,
      [ProfilePageSection.AuthoredMedia]: this.authoredMedia.get().length,
      [ProfilePageSection.Stats]: stats,
      [ProfilePageSection.People]: shouldShowPeople,
      // TODO: All sections that are related to relationships are currently
      // updated in buildWorkAndEducationSections. Using the old `workSections`
      // is deprecated and should eventually be removed. Old code is kept here
      // to help in removing `workHistory` through the codebase later.
      /* [ProfilePageSection.WorkHistory]: workSections[ProfilePageSection.WorkHistory]?.length,
       [ProfilePageSection.Investments]: workSections[ProfilePageSection.Investments]?.length,
       [ProfilePageSection.OtherExperience]:
       workSections[ProfilePageSection.OtherExperience]?.length,
      [ProfilePageSection.Education]: linkedinProfile?.education?.length,
       */
      [ProfilePageSection.WorkHistory]: 0,
      [ProfilePageSection.Investments]: 0,
      [ProfilePageSection.OtherExperience]: 0,
      [ProfilePageSection.Volunteering]: 0,
      [ProfilePageSection.Education]: 0,
      [ProfilePageSection.Funding]: false,
      [ProfilePageSection.Sources]: true,
    };

    this.profileSections.set(sections);
    this.workSections.set(workSections);
  };

  extractSocialInfos = (relevantSources: EntitySource[], entity: Entity): SocialInfo[] => {
    return relevantSources
      .map((s) => s.url)
      .concat(entity.url)
      .reduce<SocialInfo[]>((acc, socialUrl) => {
        if (isValidProfileUrl(socialUrl)) {
          const socialInfo = extractSocialInfo(socialUrl, entity.type);
          if (socialInfo) {
            socialInfo.url = this.getSocialInfoUrl(socialInfo);
            acc.push(socialInfo);
          }
        }
        return acc;
      }, []);
  };

  getSocialInfoUrl = (socialInfo: SocialInfo) => {
    const { service, url, profileUrl, companyProfileUrl } = socialInfo;
    if (service === SocialServices.LinkedIn) {
      return url.includes("linkedin.com/in") ? (profileUrl ?? url) : (companyProfileUrl ?? url);
    }
    return profileUrl ?? url;
  };

  loadPosts = async (infos: SocialInfo[]) => {
    type SocialInfoWithPosts = SocialInfo & {
      posts?: SocialPosts;
      followers?: number;
      following?: number;
      recentPostCount?: number;
    };
    return Promise.all(
      infos.reduce<Promise<SocialInfoWithPosts>[]>((acc, socialInfo) => {
        const { service, url } = socialInfo;
        const apiSupportedService =
          service === SocialServices.LinkedIn || service === SocialServices.Twitter;
        if (apiSupportedService && url) {
          acc.push(
            new Promise((resolve) => {
              return API.fetchSocialPosts({ service, url }).then((posts) => {
                resolve({
                  ...socialInfo,
                  posts: posts || undefined,
                  followers: posts?.followers,
                  following: posts?.following,
                  recentPostCount: posts?.recentPostCount,
                });
              });
            }),
          );
        } else {
          acc.push(Promise.resolve(socialInfo));
        }
        return acc;
      }, []),
    );
  };

  getEntityText = async () => {
    eventTracker.capture("download-profile-as-text", { entityId: this.entity.get().id });
    const text = await API.entityToText({ entityId: this.entity.get().id });
    const timestamp = new Date().toISOString().replace(/[:.]/g, "-");
    const blob = new Blob([text], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${this.entity.get().name}_${timestamp}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    return text;
  };

  extractLinkedInProfileAttributes(url: string): { followers: string; connections: string } | {} {
    const liProfile = findAttribute(this.attributes.get(), AttributeType.LinkedinProfile)?.value
      ?.profile;

    // this prevents multiple legacy linkedin profiles (i.e. those not
    // stored in social accounts) from displaying the same stats.
    // Only displays stats for the current entity's linkedin profile.
    const fixedUrl = cleanBaseUrl(liProfile?.url ?? "");
    if (fixedUrl !== url) return {};

    return liProfile ?
        {
          followers: liProfile.followers,
          connections: liProfile.connections,
        }
      : {};
  }

  loadSocials = async (
    relevantSources: EntitySource[],
    entity: Entity,
  ): Promise<SocialAccount[]> => {
    this.socials.set([]);
    const infos = this.extractSocialInfos(relevantSources, entity);
    const socials = await this.loadPosts(infos)
      .then((infosWithPosts) => {
        return infosWithPosts.map(({ service, url, username, posts }) => {
          const liProfileAttributes = this.extractLinkedInProfileAttributes(url);
          const useLegacyLinkedInProfileAttributes =
            service === SocialServices.LinkedIn && (!posts?.followers || !posts?.connections);
          return {
            service,
            url,
            username,
            posts: posts?.posts,
            followers: posts?.followers,
            following: posts?.following,
            connections: posts?.connections,
            recentPostCount: posts?.recentPostCount,
            ...(useLegacyLinkedInProfileAttributes ? liProfileAttributes : {}),
          };
        });
      })
      .then((socials) => {
        return socials.reduce<Record<string, SocialAccount>>((acc, s) => {
          const key = `${s.service}:${s.username}`.toLowerCase();
          if (!acc[key] || acc[key].url === entity.url) {
            acc[key] = s;
          } else {
            logger.debug("Ignoring duplicate social account", key);
            logger.debug("Existing", acc[key]);
            logger.debug("New", s);
          }
          return acc;
        }, {});
      });

    // Filter out LinkedIn URLs based on entity type
    const filteredSocials = Object.values(socials).filter((social) => {
      if (entity.type === EntityType.Person && social.url.includes("linkedin.com/company")) {
        logger.debug("Ignoring company social account for person", social.url);
        return false;
      }
      if (entity.type === EntityType.Company && social.url.includes("linkedin.com/in")) {
        logger.debug("Ignoring person social account for company", social.url);
        return false;
      }
      return true;
    });

    return filteredSocials;
  };

  loadAuthoredMedia = async (entity: Entity) => {
    try {
      const data = await API.getAuthoredMedia({ entityId: entity.id });
      this.authoredMedia.set(data.media);
      this.profileSections.setKey(ProfilePageSection.AuthoredMedia, data.media.length);
    } catch (e) {
      toast.error("Error loading authored media");
      errorTracker.sendError(e);
    }
  };

  addAuthoredMediaModal = () => {
    const user = uiStore.user.get();
    if (!user) {
      return;
    }
    const isProfileOwner = entityIsUser(this.entity.get(), user);

    uiStore.showInputModal.set({
      type: "add",
      title: "Add Authored Media",
      subtitle: `Add a url to an article, blog post, or other content that ${isProfileOwner ? "you" : this.entity.get().name} authored`,
      fields: [
        {
          placeholder: "Enter the URL",
        },
      ],
      onSubmit: async (values: string[]) => {
        try {
          const result = await API.createAuthoredMedia({
            entityId: this.entity.get().id,
            urls: values,
          });
          const existingMedia = this.authoredMedia.get();
          const newMedia = result.media.filter(
            (media) => !existingMedia.some((existing) => existing.id === media.id),
          );
          this.authoredMedia.set([...existingMedia, ...newMedia]);
          this.profileSections.setKey(
            ProfilePageSection.AuthoredMedia,
            this.authoredMedia.get().length,
          );
        } catch (e) {
          errorTracker.sendError(e, { source: "create-authored-media" });
          toast.error("Error creating authored media: " + prettyError(e));
        }
      },
    });
  };

  deleteAuthoredMedia = async (entityId: string, url: string) => {
    try {
      const count = await API.deleteAuthoredMedia({ entityId, url });
      if (count.count == 0) {
        toast.error("Authored media not found");
      }
      this.authoredMedia.set(this.authoredMedia.get().filter((m) => m.url !== url));
      this.profileSections.setKey(
        ProfilePageSection.AuthoredMedia,
        this.authoredMedia.get().length,
      );
    } catch (e) {
      errorTracker.sendError(e, { source: "delete-authored-media" });
      toast.error("Error deleting authored media: " + prettyError(e));
    }
  };

  loadMutualConnections = async (entity: Entity) => {
    const user = uiStore.user.get();
    if (this.isLoadingMutualConnections || !user || entityIsUser(entity, user)) {
      return;
    }

    this.isLoadingMutualConnections = true;

    try {
      const data = await API.getMutualConnections({
        entityId: entity.id,
      });
      this.mutualConnectionsStatus.set(data.status);
      if (data.status === MutualConnectionsStatus.Pending) {
        this.mutualConnections.set({});
        this.profileSections.setKey(ProfilePageSection.MutualConnections, 0);
      }
      if (data.connections.length) {
        data.connections.forEach((connection) => {
          const resolved = this.mutualConnections.get()[connection.linkedinUrl]?.resolved;
          const asEntity = this.mutualConnections.get()[connection.linkedinUrl]?.entity;
          this.mutualConnections.setKey(connection.linkedinUrl, {
            data: connection,
            resolved: resolved || false,
            entity: asEntity,
          });
        });
        if (entity.type === EntityType.Person) {
          // We show the number of mutual connections for people in its own
          // section, but for companies we show it in the People section.
          this.profileSections.setKey(
            ProfilePageSection.MutualConnections,
            data.connections.length,
          );
        }
        await this.resolveMutualConnections();
      }
      if (
        data.status === MutualConnectionsStatus.Pending ||
        data.status === MutualConnectionsStatus.Reloading
      ) {
        void this.subscribeToMutualConnections(entity.id);
      }
    } catch (e) {
      toast.error("Error loading mutual connections");
      errorTracker.sendError(e);
    }
  };

  resolveMutualConnections = async (
    start?: number,
    end?: number,
  ): Promise<Record<string, EntityWithAttributes> | null> => {
    start = start || 0;
    end = end || undefined;

    const batch = Object.values(this.mutualConnections.get())
      .slice(start, end)
      .filter((c) => !c.resolved);
    if (!batch.length) return null;

    const resolved = (await API.resolveAllEntities({
      queries: batch.map((c) => c.data.linkedinUrl),
    })) as Record<string, EntityWithAttributes> | null; // Cast the resolved value
    if (resolved) {
      Object.entries(resolved).forEach(([url, entity]) => {
        this.mutualConnections.setKey(url, {
          ...this.mutualConnections.get()[url],
          entity,
          resolved: true,
        });
      });
    }
    return resolved;
  };

  loadSources = async (entity: Entity) => {
    const data = await API.getSources({ entityId: entity.id });
    data.sources.sort((a, b) =>
      b.starred && !a.starred ? 1
      : a.starred && !b.starred ? -1
      : (b.relevance ?? 5) - (a.relevance ?? 5),
    );
    this.sources.set(data.sources);
    const relevantSources = data.sources.filter(
      (s) => (s.starred || (s.relevance ?? 5) > 3) && s.isRight != SourceIsRight.No,
    );
    const socialSources = relevantSources.filter((s) => s.isRight == SourceIsRight.Yes);
    const sourceCount = relevantSources.length;
    this.sourceCount.set(sourceCount);

    this.images.set(data.images);
    data.scraped.forEach((result) => {
      this.crawlResults.setKey(result.url, result);
    });
    this.sourceUpdates.set(data.sourceUpdates.filter((update) => !update.deletedAt));

    const socials = socialSources.length > 0 ? await this.loadSocials(socialSources, entity) : [];

    this.socials.set(socials);
    this.profileSections.setKey(ProfilePageSection.SocialMedia, socials.length);
    this.profileSections.setKey(ProfilePageSection.Sources, sourceCount || true);

    const crawlResults = this.crawlResults.get();
    const authoredMediaUrls = this.authoredMedia.get().map((m) => m.url);
    const media = relevantSources
      .map((s) => crawlResults[s.url])
      .filter(Boolean)
      .filter((c) => {
        const articleType = c.structuredData?.find((s) =>
          (s["@type"] as string | undefined)?.includes("Article"),
        );
        const typeFromGraph = c.structuredData
          ?.flatMap((s) => s["@graph"])
          .filter(
            (g) =>
              (g as { "@type"?: string; "@id"?: string })?.["@type"]?.includes("NewsArticle") &&
              (g as { "@type"?: string; "@id"?: string })?.["@id"]?.includes(c.url),
          );
        return Boolean(articleType || typeFromGraph?.length);
      })
      .filter((c) => !authoredMediaUrls.includes(c.url));
    this.mediaArticles.set(media);
    this.profileSections.setKey(ProfilePageSection.MediaCoverage, media.length);
  };

  refreshHighlights = async () => {
    const data = await API.getHighlights({ entityId: this.entity.get().id });
    const highlights = data.map((h) => ({
      description: h.text,
      // citationIds can be urls too
      citationIds: h.sources?.filter((s) => !s.startsWith("user://")) || [],
      userSources: h.sources?.filter((s) => s.startsWith("user://")) || [],
    }));

    this.highlights.set(highlights);
  };

  updateHighlightsFromFeedback = () => {
    const previousFeedback =
      this.highlightsFeedback.get()?.map((f) => {
        const timeAgo = formatDistanceToNow(f.createdAt);
        const authorInfo = f.author ? ` by ${f.author}` : "";
        return `${f.feedback} (${timeAgo} ago${authorInfo})`;
      }) || [];

    this.showHighlightsFeedbackModal.set({
      previousFeedback,
      onSubmit: (values: string) => {
        // No optimistic update here since update is AI generated on the input
        const data = API.updateHighlightsFromFeedback({
          entityId: this.entity.get().id,
          feedback: values,
        });

        eventTracker.capture("entity-update-highlights", {
          entityId: this.entity.get().id,
          feedback: values,
        });

        toast
          .promise(data, {
            pending: "Updating highlights...",
            success: "Finished!",
            error: "Error re-writing profile",
          })
          .then((data) => {
            this.highlights.set(
              data.highlights.map((h) => ({
                description: h.text,
                citationIds: h.sources?.filter((s) => !s.startsWith("user://")) || [],
                userSources: h.sources?.filter((s) => s.startsWith("user://")) || [],
              })),
            );

            this.highlightsFeedback.set([data.feedback, ...this.highlightsFeedback.get()]);
          })
          .catch((error: unknown) => {
            toast.error(prettyError(error));
          });
      },
    });
  };

  loadHighlightsFeedback = async (entity: Entity) => {
    const user = uiStore.user.get();
    if (!user) return;

    if (!uiStore.isDev.get() && !entityIsUser(entity, user)) return;
    const data = await API.getHighlightsFeedback({ entityId: entity.id });
    this.highlightsFeedback.set(data.feedback);
  };

  loadFundingData = (entity: Entity) => {
    if (entity.type === EntityType.Person) return;
    const crawlResults = this.crawlResults.get();

    const crunchbase = this.sources
      .get()
      .find(
        (r) =>
          r.relevance &&
          r.relevance > 3 &&
          r.url.includes("crunchbase.com/organization") &&
          crawlResults[r.url]?.structuredData?.length,
      );

    const crunchbaseData =
      crunchbase &&
      (crawlResults[crunchbase.url].structuredData?.find((s) => s.mainEntity)
        ?.mainEntity as CrunchbaseData);

    this.fundingData.set(crunchbaseData);
    this.profileSections.setKey(ProfilePageSection.Funding, !!crunchbaseData);
  };

  loadRelationships = async (entity: Entity) => {
    this.relationshipsLoading.set(true);

    try {
      const relationships = await API.relationships.list({
        entityId: entity.id,
        type: entity.type,
      });
      const relationshipsTo = await API.relationshipsTo({ entityId: entity.id });
      const allRelationships = [...relationships, ...relationshipsTo];
      const filteredRelationships = allRelationships.filter((r) => !!r.toId || !!r.toName);
      if (allRelationships.length !== filteredRelationships.length) {
        errorTracker.sendError(new Error("Relationships with missing toId or toName"), {
          entityId: entity.id || "",
          entityType: entity.type || "",
          slug: entity.slug || "",
        });
      }
      this.relationships.set(filteredRelationships);

      const linkedEntities = filteredRelationships
        .filter((r) => r.toId && r.fromId)
        .map((r) => {
          const isToSelf = r.toId == entity.id;
          const key = isToSelf ? r.fromId : r.toId;
          const value = isToSelf ? r.from : r.to;

          return [key, value] as [string, EntityWithAttributes];
        });
      this.linkedEntities.set(Object.fromEntries(linkedEntities));

      if (entity.type == EntityType.Person) {
        this.loadWorkAndEducationSections(relationships.filter((r) => !!r.toName || !!r.toId));
      }
    } catch (e) {
      errorTracker.sendError(e);
      if (uiStore.user.get()) toast.error("Error loading history");
    } finally {
      this.relationshipsLoading.set(false);
    }
  };

  updateRelationship = async (relationship: Partial<RelationshipWithEntity> & { id: string }) => {
    eventTracker.capture("entity-update-relationship", {
      toId: relationship.toId,
      toName: relationship.toName,
      updates: relationship,
    });
    const currentRelationships = this.relationships.get() || [];
    const updatedRelationships = currentRelationships.map((r) =>
      r.id === relationship.id ? { ...r, ...relationship } : r,
    );
    this.relationships.set(updatedRelationships);
    this.loadWorkAndEducationSections(updatedRelationships);
    try {
      await API.relationships.update(relationship.id, relationship);
    } catch (e) {
      // Reverting optimistic update.
      this.relationships.set(currentRelationships);
      this.loadWorkAndEducationSections(currentRelationships);
      throw e;
    }
  };

  createRelationship = async (relationship: Partial<RelationshipWithEntity>) => {
    eventTracker.capture("entity-create-relationship", {
      relationship,
    });
    // No optimistic update here in order to make sure that all the data in the
    // store represents some entity in the database. Otherwise editing will
    // fail (since there is no id).
    const currentRelationships = this.relationships.get() || [];
    const addedRelationship = await API.relationships.create(relationship);
    const updatedRelationships = [
      ...currentRelationships,
      {
        ...addedRelationship,
        // Relationship returned from the API does not include `to`, but the relationship
        // we are being asked to create might (if we are creating a new relationship).
        to: relationship.to,
      },
    ];
    this.relationships.set(updatedRelationships);
    this.loadWorkAndEducationSections(updatedRelationships);
  };

  deleteRelationship = async (relationship: Partial<RelationshipWithEntity> & { id: string }) => {
    eventTracker.capture("entity-delete-relationship", {
      toId: relationship.toId,
      toName: relationship.toName,
    });
    const currentRelationships = this.relationships.get() || [];
    const updatedRelationships = currentRelationships.filter((r) => r.id !== relationship.id);
    this.relationships.set(updatedRelationships);
    this.loadWorkAndEducationSections(updatedRelationships);
    try {
      await API.relationships.delete(relationship.id);
    } catch (e) {
      // Reverting optimistic update.
      this.relationships.set(currentRelationships);
      this.loadWorkAndEducationSections(currentRelationships);
      throw e;
    }
  };

  batchDeleteRelationships = async ({
    toId,
    toName,
    entityId,
  }: {
    toId?: string;
    toName?: string;
    entityId: string;
  }) => {
    if (toId && toName) {
      throw new Error("toId and toName are mutually exclusive");
    }
    eventTracker.capture("entity-delete-relationship", {
      toId,
      toName,
    });
    const currentRelationships = this.relationships.get() || [];
    const updatedRelationships = currentRelationships.filter(
      (r) => r.toId !== toId && r.toName !== toName,
    );
    this.relationships.set(updatedRelationships);
    this.loadWorkAndEducationSections(updatedRelationships);
    try {
      await API.batchDeleteRelationships({
        toId,
        toName,
        entityId,
      });
    } catch (e) {
      // Reverting optimistic update.
      this.relationships.set(currentRelationships);
      this.loadWorkAndEducationSections(currentRelationships);
      throw e;
    }
  };

  batchUpdateRelationships = async (
    {
      toId,
      toName,
      entityId,
    }: {
      toId?: string;
      toName?: string;
      entityId: string;
    },
    updates: Partial<RelationshipWithEntity>,
  ) => {
    if (toId && toName) {
      throw new Error("toId and toName are mutually exclusive");
    }
    eventTracker.capture("entity-update-relationship", {
      toId,
      toName,
      updates,
    });
    const currentRelationships = this.relationships.get() || [];
    const updatedRelationships = currentRelationships.map((r) => {
      if (r.toId === toId || r.toName === toName) {
        return { ...r, ...updates };
      }
      return r;
    });
    this.relationships.set(updatedRelationships);
    this.loadWorkAndEducationSections(updatedRelationships);
    try {
      await API.batchUpdateRelationships(updates, {
        toId,
        toName,
        entityId,
      });
      if (updates.toId || updates.toName) {
        await this.loadRelationships(this.entity.get());
      }
    } catch (e) {
      // Reverting optimistic update.
      this.relationships.set(currentRelationships);
      this.loadWorkAndEducationSections(currentRelationships);
      throw e;
    }
  };

  loadWorkAndEducationSections = (relationships: RelationshipWithEntity[]) => {
    const relationshipSections = buildWorkAndEducationSections(relationships);
    this.relationshipSections.set({ ...relationshipSections });
    for (const [section, relationships] of Object.entries(relationshipSections)) {
      this.profileSections.setKey(section as ProfilePageSection, relationships.length);
    }
  };

  loadSearchFilter = async (entity: Entity) => {
    if (!uiStore.showDevTools()) return;
    const filter = await API.getFilter({ entityId: entity.id });
    this.searchFilter.set(filter);
  };

  loadSearchQuery = async (entity: Entity) => {
    if (!uiStore.showDevTools()) return;
    const query = await API.getQuery({ entityId: entity.id });
    this.searchQuery.set(query);
  };

  // This triggers a whole update workflow.
  // This should NOT be used to modify the entity itself.
  triggerEntityRefresh = async () => {
    const entityId = this.entity.get().id;
    void this.subscribe(entityId);
    this.updateEntityQueue.set({
      ...this.updateEntityQueue.get(),
      [entityId]: { resolved: false },
    });
    eventTracker.capture("update-entity", { entityId: entityId });
    try {
      await API.triggerEntityRefresh({ entityId: entityId });
    } catch (e) {
      errorTracker.sendError(e, { source: "update-entity" });
    }
  };

  updateEntityFields = async (id: string, updates: Partial<Entity>) => {
    const currentEntity = this.entity.get();
    if (currentEntity.id === id) {
      // optimistic update
      this.entity.set({ ...currentEntity, ...updates });
    }
    try {
      const newEntity = await API.entities.update(id, updates);
      this.entity.set(newEntity);
    } catch (e) {
      if (currentEntity.id === id) {
        // revert optimistic update
        this.entity.set(currentEntity);
      }
      errorTracker.sendError(e);
      toast.error("Error updating entity fields: " + prettyError(e));
    }
  };

  addSource = () => {
    uiStore.showInputModal.set({
      type: "add",
      title: "Add Source",
      subtitle: "Enter the URL of a source to add",
      fields: [
        {
          placeholder: "https://example.com",
        },
      ],
      onSubmit: async (values: string[]) => {
        const url = values[0];
        await this.addSourceUrl(url, "manual");
      },
    });
  };

  addSourceUrl = async (url: string, sourceOfSource: string) => {
    const _validated = new URL(url); // parse url
    eventTracker.capture("entity-add-source", {
      entityId: this.entity.get().id,
      url,
      sourceOfSource,
    });
    this.starredSourcesUpdated.set(true);
    try {
      const source = await API.sources.create({
        entityId: this.entity.get().id,
        url,
        source: sourceOfSource,
      });
      await this.loadSingleSource(source.id);
      toast.success("Source added", { autoClose: 2000 });
      await this.triggerOverviewRefreshWithDelay();
      void this.summarizeSource(source);
    } catch (e) {
      errorTracker.sendError(e, { source: "add-source-url" });
      toast.error("Error adding source: " + prettyError(e));
    }
  };

  triggerOverviewRefresh = async () => {
    toast.info("Refreshing profile...");
    try {
      await API.triggerHatchetEvent({
        event: HatchetWorkflow.RegenerateOverview,
        input: {
          userId: uiStore.user.get()?.id,
          entityId: this.entity.get().id,
          skipCache: true,
          regenerate: true,
        },
      });
    } catch (e) {
      errorTracker.sendError(e);
      toast.error("Failed to regenerate overview");
    }
  };

  triggerOverviewRefreshWithDelay = async () => {
    const lastRefresh = this.overviewRefreshTriggered.get();
    const refreshWindow = DELAYED_REFRESH_WINDOW;
    if (
      lastRefresh == undefined ||
      new Date().getTime() - new Date(lastRefresh).getTime() > refreshWindow
    ) {
      toast.info("Scheduling profile refresh...");
      try {
        await API.triggerHatchetEvent({
          event: HatchetWorkflow.RegenerateOverview,
          input: {
            userId: uiStore.user.get()?.id,
            entityId: this.entity.get().id,
            delayUntil: new Date(new Date().getTime() + refreshWindow).toISOString(),
            skipCache: true,
            regenerate: true,
          },
        });
      } catch (e) {
        errorTracker.sendError(e);
        toast.error("Failed to regenerate overview");
      }
    }
    this.overviewRefreshTriggered.set(new Date().toISOString());
  };

  summarizeSource = async (source: EntitySource, resummarize: boolean = false) => {
    if (source.summary && !resummarize) return;
    this.summariesInProgress.set({ ...this.summariesInProgress.get(), [source.url]: true });
    try {
      const response = await API.summarizeSource({
        entityId: this.entity.get().id,
        sourceId: source.id,
      });
      const sanitized = sanitizeLinks([source.url])[0];
      const summarized = response.summarized[sanitized] || response.summarized[source.url];
      if (summarized) {
        this.sources.set(
          this.sources
            .get()
            .map((s) => (s.url == source.url ? { ...s, summary: summarized.join("\n") } : s)),
        );
      } else {
        errorTracker.sendError(new Error("No summary generated for source"), {
          source: "summarize-source",
          url: source.url,
        });
        toast.error("No summary generated for source: " + source.url);
      }
    } catch (e) {
      errorTracker.sendError(e, { source: source.url });
    } finally {
      this.summariesInProgress.set({ ...this.summariesInProgress.get(), [source.url]: false });
    }
  };

  upsertNote = (source: EntitySource, existingNote?: string) => {
    const isAddingNote = !existingNote;
    const modalType = isAddingNote ? "add" : "edit";
    const modalTitle = isAddingNote ? "Add a note to the AI" : "Edit Note";
    const modalSubtitle =
      isAddingNote ?
        "Help our AI understand this source better - how is it related? Is anything incorrect?"
      : "Edit the note for this source";

    uiStore.showInputModal.set({
      type: modalType,
      title: modalTitle,
      subtitle: modalSubtitle,
      fields: [
        {
          placeholder: "Enter your note",
          currentValue: existingNote,
        },
      ],
      onSubmit: async (values: string[]) => {
        const notes = values[0];
        const newNote = await API.sourceUpdates.create(this.entity.get(), {
          url: source.url,
          notes,
        });
        this.sourceUpdates.set([
          ...this.sourceUpdates.get().filter((u) => u.url != source.url),
          newNote,
        ]);
        void this.summarizeSource(source, true);
        await this.triggerOverviewRefreshWithDelay();
      },
    });
  };

  deleteNote = async (source: EntitySource) => {
    const update = this.sourceUpdates.get().find((u) => u.url === source.url);
    if (update) {
      await API.sourceUpdates.update(this.entity.get().id, update.id, { deletedAt: new Date() });
      this.sourceUpdates.set(this.sourceUpdates.get().filter((u) => u.url != source.url));
      await this.triggerOverviewRefreshWithDelay();
    }
  };

  starSource = async (url: string, starred: boolean) => {
    const sources = this.sources.get();
    const source = sources.find((s) => s.url == url);
    if (!source) return;

    this.sources.set(sources.map((s) => (s.url == url ? { ...s, starred } : s)));
    this.starredSourcesUpdated.set(true);
    eventTracker.capture(starred ? "entity-star-source" : "entity-unstar-source", {
      entityId: this.entity.get().id,
    });
    try {
      await API.sources.update(source.id, { starred });
    } catch (e) {
      errorTracker.sendError(e, { source: source.url });
      toast.error("Error updating source");
    }
  };

  markRelevant = async (url: string, relevant: boolean) => {
    const sources = this.sources.get();
    const source = sources.find((s) => s.url == url);
    if (!source) return;

    const wasStarred = source.starred;
    const relevance = relevant ? 5 : 0;
    this.sources.set(
      sources.map((s) =>
        s.url == url ? { ...s, relevance, starred: !relevant ? false : s.starred } : s,
      ),
    );
    if (wasStarred) this.starredSourcesUpdated.set(true);
    eventTracker.capture(relevant ? "entity-mark-relevant" : "entity-mark-unhelpful", {
      starred: source.starred,
      entityId: this.entity.get().id,
    });
    try {
      await API.sources.update(source.id, {
        relevance,
        starred: !relevant ? false : source.starred,
      });
    } catch (e) {
      errorTracker.sendError(e, { source: source.url });
      toast.error("Error updating source");
    }
  };

  private sourceToastId: string | number | null = null;
  private sourceToastDismissedByClick = false;

  updateSourceCategory = async (source: EntitySource, category: SourceCat) => {
    const updates: Partial<EntitySource> & { category: SourceCat } = {
      category,
      hidden: false,
    };
    if (source.error) updates.error = null;
    switch (category) {
      case SourceCat.Wrong:
        updates.isRight = SourceIsRight.No;
        break;
      case SourceCat.Starred:
        updates.isRight = SourceIsRight.Yes;
        updates.starred = true;
        break;
      case SourceCat.Unsure:
        updates.isRight = SourceIsRight.Unsure;
        updates.starred = false;
        break;
      case SourceCat.Relevant:
      case SourceCat.Unhelpful:
        updates.starred = false;
        updates.isRight = SourceIsRight.Yes;
        updates.relevance = category == SourceCat.Relevant ? 5 : 0;
        break;
      case SourceCat.Hidden:
        updates.starred = false;
        updates.hidden = true;
        break;
      case SourceCat.Duplicate:
        updates.starred = true;
        updates.isRight = SourceIsRight.Duplicate;
        break;
      default:
        logger.warn("did not modify source", source, category);
        return;
    }

    this.starredSourcesUpdated.set(true);
    const updatedSource = { ...source, ...updates };
    logger.info("source updated", category, updatedSource);
    eventTracker.capture("entity-source-categorize", {
      source: source.url,
      category: category,
      entityId: this.entity.get().id,
    });
    this.sources.set(this.sources.get().map((s) => (s.url == source.url ? updatedSource : s)));
    try {
      await API.sources.update(source.id, updates);
    } catch (e) {
      errorTracker.sendError(e, { source: source.url });
      toast.error("Error updating source");
    }
    if (updatedSource.starred || (updatedSource.relevance ?? 0) > 3) {
      await this.summarizeSource(updatedSource);
    }

    const toastText = "Profile will update in 10 seconds. Click here to cancel...";
    const toastConfig: Partial<ToastOptions> = {
      autoClose: 10000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      closeButton: false,
      onOpen: () => {
        this.sourceToastDismissedByClick = false;
      },
      onClick: () => {
        this.sourceToastDismissedByClick = true;
        if (this.sourceToastId !== null) {
          toast.dismiss(this.sourceToastId);
        }
      },
      onClose: () => {
        this.sourceToastId = null;
        if (!this.sourceToastDismissedByClick) {
          void this.triggerOverviewRefresh();
        }
        this.sourceToastDismissedByClick = false; // Reset for next time
      },
    };

    // Check for active toast and update it
    if (this.sourceToastId) {
      toast.update(this.sourceToastId, {
        render: toastText,
        ...toastConfig,
      });
    } else {
      this.sourceToastId = toast.info(toastText, {
        ...toastConfig,
      });
    }
  };

  regenerateOverview = () => {
    uiStore.showConfirmModal.set({
      type: "warning",
      title: "Regenerate Overview",
      subtitle: "Regenerate overview based on updated starred articles?",
      onClick: async () => {
        eventTracker.capture("entity-regenerate-overview", { entityId: this.entity.get().id });
        const promise = API.sourcesRegenerate({ entityId: this.entity.get().id });
        await toast.promise(promise, {
          pending: "Regenerating overview...",
          success: "Finished!",
          error: "Error generating overview",
        });
      },
    });
  };

  deleteHighlights = () => {
    uiStore.showConfirmModal.set({
      type: "danger",
      title: "Delete Highlights?",
      subtitle: "You will need to rebuild this profile.",
      onClick: async () => {
        this.highlights.set(undefined);
        await API.deleteHighlights({ entityId: this.entity.get().id });
      },
    });
  };

  clearAllSources = () => {
    uiStore.showConfirmModal.set({
      type: "danger",
      title: "Clear all sources",
      subtitle: "You will need to rebuild this profile.",
      onClick: async () => {
        await API.sourcesClearAll({ entityId: this.entity.get().id });
        void this.loadSources(this.entity.get());
      },
    });
  };

  loadSingleSource = async (id: string) => {
    const source = await API.getSources({ entityId: this.entity.get().id, sourceId: id });
    const sources = [...this.sources.get(), ...source.sources];
    sources.sort((a, b) =>
      b.starred && !a.starred ? 1
      : a.starred && !b.starred ? -1
      : (b.relevance ?? 5) - (a.relevance ?? 5),
    );
    this.sources.set(sources);
    source.scraped.forEach((c) => {
      this.crawlResults.setKey(c.url, c);
    });
  };

  deleteEntity = () => {
    if (!uiStore.showDevTools()) return;
    uiStore.showConfirmModal.set({
      type: "danger",
      title: "Delete Entity",
      subtitle: "Are you sure? You will need to rebuild this profile.",
      onClick: async () => {
        try {
          const result = await API.deleteEntity({ entityId: this.entity.get().id });
          if (result.redirect) {
            uiStore.routeTo(result.redirect);
            // this was displaying before the redirect and being lost
            setTimeout(() => {
              toast.success("Entity deleted");
            }, 1000);
          }
        } catch (e) {
          errorTracker.sendError(e, { source: "delete-entity" });
          toast.error("Error deleting entity: " + prettyError(e));
        }
      },
    });
  };

  search = async (query: string, page?: number) => {
    if (!uiStore.user.get()) return;
    this.searchState.set({ query, page: page || 0 });
    this.searchResults.set("loading");
    try {
      const results = await extensionScraper.searchWeb({ q: query, page: page });
      this.searchResults.set(results);
    } catch (e) {
      errorTracker.sendError(e, { query, page });
      toast.error("Error searching: " + prettyError(e));
      this.searchResults.set(undefined);
    }
  };

  triggerPipeline = async () => {
    const entity = this.entity.get();
    const event =
      entity.type == EntityType.Person ?
        HatchetWorkflow.PersonLoader
      : HatchetWorkflow.CompanyLoader;
    const user = uiStore.user.get();
    if (!user) return;
    await API.triggerHatchetEvent({
      event,
      input: {
        entityId: entity.id,
        userId: user.id,
        reason: "ui button",
      },
    });
  };

  loadLinkedCompany = async (url: string) => {
    await API.resolveEntity({ query: url }).then((entitySnap) => {
      if (entitySnap) this.linkedCompanyMap.setKey(url, entitySnap.entity);
    });
  };

  isRefreshing = false;
  refresh = () => {
    const oldEntity = this.entity.get();
    void API.resolveWithSnapshot({ query: this.entity.get().url })
      .then(async (entitySnap) => {
        if (entitySnap) {
          if (oldEntity?.id !== entitySnap.entity.id && entitySnap.entity.slug) {
            // entity changed, redirect to new entity
            uiStore.routeTo(entitySnap.entity.slug);
          }
          await this.load(entitySnap.entity, entitySnap.snapshot, {
            attributes: entitySnap.entity.attributes,
          });
        }
      })
      .catch((e: unknown) => {
        logger.error("Error refreshing sidebar entity", e);
        toast.error("Error refreshing: " + prettyError(e));
      });
  };

  updateFact = async <T extends FactType>(type: T, value: FactValueSet[T]) => {
    eventTracker.capture("entity-update-fact", {
      isYou: this.isYou.get(),
      type,
      value: String(value),
    });
    logger.info("updateFact", type, value);
    eventTracker.capture("entity-update-fact", {
      isYou: this.isYou.get(),
      type,
      value: String(value),
    });
    const entity = this.entity.get();
    try {
      const fact = await API.facts.create(entity, { entityId: entity.id, type, value });
      const snapshot = await API.getSnapshot({ entityId: entity.id });
      this.facts.set({ ...this.facts.get(), [type]: fact });
      this.snapshot.set(snapshot);

      if (type == PersonFact.Pronouns) void this.onPronounsUpdated(value as string);
    } catch (e) {
      errorTracker.sendError(e, { type, value: String(value) });
      toast.error(prettyError(e));
    }
  };

  private onPronounsUpdated = async (pronouns: string) => {
    const reloading = API.pronounsUpdated({ entityId: this.entity.get().id, pronouns });
    await toast.promise(reloading, {
      pending: "Re-writing profile...",
      success: "Finished!",
      error: "Error re-writing profile",
    });
    this.refresh();
  };

  updateEntityImage = async (imgSrc: string) => {
    const newEntity = await API.entities.update(this.entity.get().id, {
      imageUrl: imgSrc,
    });
    this.entity.set(newEntity);
  };

  mergeEntities = () => {
    uiStore.showInputModal.set({
      type: "warning",
      title: "Merge Entities",
      subtitle: "Enter the URL of the new entity. THIS CURRENT ENTITY will be NO MORE.",
      fields: [
        {
          placeholder: "https://distill.fyi/c/NewCo",
        },
      ],
      onSubmit: async (values: string[]) => {
        const url = values[0];
        const result = await API.mergeEntities({
          fromEntityId: this.entity.get().id,
          toEntityUrl: url,
        });
        if (result.redirect) {
          uiStore.routeTo(result.redirect);
        }
      },
    });
  };

  // --- live subscription

  unsubscribe: (() => void) | null = null;

  subscribe = async (entityId: string) => {
    if (!uiStore.user.get()) return;
    const realtime = await uiStore.getConnectedRealtime();
    const channel = realtime.channels.get("entity:" + entityId);

    if (channel) {
      updateUnsubscribe(this, () => channel.unsubscribe());

      channel.subscribe("reload", (msg) => {
        // reloads entity data from the server (without refreshing the whole page)
        this.refresh();
      });

      channel.subscribe("refresh-beta", (msg) => {
        posthog.onFeatureFlags(() => {
          if (posthog.isFeatureEnabled(FeatureFlag.BetaUI)) {
            logger.info("betaUI enabled, refreshing");
            this.refresh();
          }
        });
      });

      channel.subscribe("progress", (msg) => {
        const progress = msg.data as PipelineProgress;
        logger.info("progress->", msg.data);
        const currentProgress = this.progress.get();
        if (!currentProgress) {
          this.loadingStarted();
        }
        const newProgress: PipelineProgress = {
          ...progress,
          step: Math.max(progress.step || 0, (currentProgress?.step || 0) + 1),
        };
        this.progress.set(newProgress);
      });

      channel.subscribe("finished", (msg) => {
        logger.info("entity finished", msg);
        const visible = document.visibilityState;
        eventTracker.capture("entity-finished", { entityId, visible });
        this.updateEntityQueue.set({
          ...this.updateEntityQueue.get(),
          [entityId]: { resolved: true },
        });
        this.progress.set(undefined);
        this.loadingEnded();
        this.refresh();
      });

      channel.subscribe("newSource", (msg) => {
        const { id } = msg.data as { id: string };
        void this.loadSingleSource(id);
      });

      channel.subscribe("newHighlights", (msg) => {
        logger.info("new highlights", msg);
        void this.refreshHighlights();
      });
    }
  };

  mutualsUnsubscribe: { unsubscribe: (() => void) | null } = { unsubscribe: null };

  subscribeToMutualConnections = async (entityId: string) => {
    if (!uiStore.user.get()) return;
    const userId = uiStore.user.get()?.id || "unknown";
    const realtime = await uiStore.getConnectedRealtime();
    const channel = realtime.channels.get("mutuals:entity:" + entityId + ":user:" + userId);

    if (channel) {
      updateUnsubscribe(this.mutualsUnsubscribe, () => channel.unsubscribe());
    }

    channel.subscribe("mutual-connections-completed", (msg) => {
      logger.info("mutual connections completed, refreshing");
      this.isLoadingMutualConnections = false;
      void this.loadMutualConnections(this.entity.get());
      updateUnsubscribe(this.mutualsUnsubscribe, () => channel.unsubscribe());
    });
  };
}

declare global {
  interface Window {
    entityStore: EntityStore;
  }
}

// there are two entity stores - one for an entity that is displayed full-page
// and one for an entity that is displayed in the sidebar. use the context
// from EntityMainContext to determine which store to use

// it is not likely you'll want to access one of these stores directly

const sidebarStore = new EntityStore();
const mainStore = new EntityStore();

export const entityStores: Record<string, EntityStore> = {};

export const EntityIdContext: Context<string | undefined> = createContext<string | undefined>(
  undefined,
);

// this hook returns the relevant entity store. It must be called within a component
export const useEntityStore = () => {
  const entityId = useContext(EntityIdContext);
  if (!entityId) {
    throw new Error(
      "useEntityStore called outside of entity context. Please you have EntityStoresProvider in your component tree",
    );
  }
  if (!entityStores[entityId]) {
    entityStores[entityId] = new EntityStore();
  }
  return entityStores[entityId];
};

export const getEntityStoreForTest = () => {
  if (!entityStores["test"]) {
    entityStores["test"] = new EntityStore();
  }
  return entityStores["test"];
};
