import { Static } from "@sinclair/typebox";
import { Value } from "@sinclair/typebox/value";

import { assertUnreachable } from "@/lib/miscUtils";
import { LinkedinSearchResult } from "@/types/attributes";
import { Entity } from "@/types/db";
import {
  EmailContact,
  EmailEntryPoint,
  EmailEvent,
  EmailEventType,
  EmailLookupSource,
  EmailParts,
  GmailContact,
  GmailContactSource,
} from "@/types/emails.typebox";

export type EmailContact = Static<typeof EmailContact>;
export function isEmailContact(u: unknown): u is EmailContact {
  return Value.Check(EmailContact, u);
}

export type GmailContactSource = Static<typeof GmailContactSource>;
export type GmailContact = Static<typeof GmailContact>;
export function isGmailContact(u: unknown): u is GmailContact {
  return Value.Check(GmailContact, u);
}

export function compareSources(a: GmailContactSource, b: GmailContactSource): number {
  if (a === b) return 0;
  switch (a) {
    case "thread":
      return -1;
    case "scrape":
      return b === "thread" ? 1 : -1;
    case "inbox":
      return 1;
    default:
      assertUnreachable(a);
  }
}

export type EmailParts = Static<typeof EmailParts>;
export function isEmailParts(u: unknown): u is EmailParts {
  return Value.Check(EmailParts, u);
}

export type AttemptCreateListEntryParams = {
  url?: string;
  email?: EmailParts;
  name: string;
};

export type EmailSearchResult = LinkedinSearchResult & {
  confidenceScore?: number;
};

export function isHighConfidenceEmail(result: EmailSearchResult): boolean {
  return !!result.confidenceScore && result.confidenceScore >= 0.9;
}

export type EmailEntryPoint = Static<typeof EmailEntryPoint>;

export function isEmailEntryPoint(u: unknown): u is EmailEntryPoint {
  return Value.Check(EmailEntryPoint, u);
}

export type EmailLookupSource = Static<typeof EmailLookupSource>;

export function isEmailLookupSource(u: unknown): u is EmailLookupSource {
  return Value.Check(EmailLookupSource, u);
}

export enum EmailResolutionStatus {
  CANDIDATE = "candidate",
  ERROR = "error",
  REQUESTED = "requested",
  RESOLVED = "resolved",
  SKIPPED = "skipped",
}

type EmailIdentityBase = {
  contact: EmailContact;
  status: EmailResolutionStatus;
};

export type CandidateEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.CANDIDATE;
  candidates: EmailSearchResult[];
  inProgress: boolean;
};

export type RequestedEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.REQUESTED;
};

export type SkippedEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.SKIPPED;
};

export type ResolvedEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.RESOLVED;
  entity: Entity;
  confirmed: boolean;
};

export type ErrorEmailIdentity = EmailIdentityBase & {
  status: EmailResolutionStatus.ERROR;
  errors: string[];
};

export type EmailIdentity =
  | CandidateEmailIdentity
  | ResolvedEmailIdentity
  | ErrorEmailIdentity
  | RequestedEmailIdentity
  | SkippedEmailIdentity;

export type EmailEventType = Static<typeof EmailEventType>;

export function isEmailEventType(u: unknown): u is EmailEventType {
  return Value.Check(EmailEventType, u);
}

export type EmailEvent = Static<typeof EmailEvent>;

export function isEmailEvent(u: unknown): u is EmailEvent {
  return Value.Check(EmailEvent, u);
}

export function needsPolling(identity: EmailIdentity): boolean {
  const { status } = identity;
  switch (status) {
    case EmailResolutionStatus.CANDIDATE:
      return identity.inProgress;
    case EmailResolutionStatus.ERROR:
      return true;
    default:
      return false;
  }
}
