/* eslint-disable @next/next/no-img-element */
import SourceTooltipLink, {
  getReferenceDataToShowFromURL,
  ReferenceData,
} from "@/components/entities/SourceTooltipLink";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { useEntityStore } from "@/stores/entityStore";
import { CompanyFact, FactPick, FactType, GenericProfile, ProfilePageSection } from "@/types";
import { buildStatsToShow } from "@/utils/entityUtils";
import { formatValue, getLabel } from "@/utils/facts";
import { useStore } from "@nanostores/react";
import { startCase, toLower, uniq, uniqWith } from "lodash";
import { HTMLAttributes, useState } from "react";
import { twMerge } from "tailwind-merge";

export default withErrorBoundary(function CompanyStats() {
  const entityStore = useEntityStore();
  const snapshot = useStore(entityStore.snapshot);
  const [refHovered, setRefHovered] = useState<number | undefined>(undefined);
  const [factHovered, setFactHovered] = useState<FactType | undefined>(undefined);

  if (!snapshot.facts) return null;
  const facts = snapshot.facts;

  const statsToShow = buildStatsToShow(facts);

  if (!statsToShow.length) {
    return null;
  }

  const factToRef: Partial<Record<FactType, number>> = {};
  const refToFact: Record<number, FactType[]> = {};
  const references: Array<ReferenceData> = uniqWith(
    (
      statsToShow
        .map((type) => {
          const ref = getReferenceDataToShowFromURL(snapshot, facts[type]?.sourceUrl || undefined);
          if (ref) {
            factToRef[type] = ref.idx;
            refToFact[ref.idx] = (refToFact[ref.idx] || []).concat([type]);
          }
          return ref;
        })
        .filter(Boolean) as ReferenceData[]
    ).toSorted((a, b) => a.idx - b.idx),
    (a, b) => a.idx === b.idx,
  );
  const factsToHighlight = (refHovered && refToFact[refHovered]) || [];
  const refToHighlight = (factHovered && factToRef[factHovered]) || undefined;

  return (
    <ProfileBubble title="Details" section={ProfilePageSection.Stats}>
      <div className="flex flex-wrap justify-between">
        {statsToShow.map((type) => (
          <StatsBubble
            onMouseOver={() => setFactHovered(type)}
            onMouseOut={() => setFactHovered(undefined)}
            key={type}
            fact={facts[type]}
            type={type}
            snapshot={snapshot}
            highlight={factsToHighlight.includes(type)}
          />
        ))}
      </div>
      {references.length > 0 && (
        <div className="mt-4 w-full items-center justify-end flex flex-row">
          <div className="mr-1">Citations:</div>
          {references.map((reference) => (
            <div
              className="mr-1"
              key={reference.idx}
              onMouseOver={() => setRefHovered(reference.idx)}
              onMouseOut={() => setRefHovered(undefined)}
            >
              <SourceTooltipLink
                highlight={refToHighlight === reference.idx}
                title={reference.url}
                href={reference?.citationUrl}
              >
                {reference.idx}
              </SourceTooltipLink>
            </div>
          ))}
        </div>
      )}
    </ProfileBubble>
  );
});

function StatsBubble<T extends FactType>({
  fact,
  type,
  snapshot,
  highlight,
  ...rest
}: {
  fact: FactPick<T> | undefined;
  type: T;
  snapshot: GenericProfile;
  highlight?: boolean;
} & HTMLAttributes<HTMLDivElement>) {
  if (!fact) return null;
  if (type == CompanyFact.State && fact.value == "POSSIBLY_INACTIVE") return null;

  const label = getLabel(type);
  const value = formatValue(type, fact.value);
  if (!value || (Array.isArray(value) && value.length === 0)) return null;

  return (
    <div
      className={twMerge(
        "flex w-full lg:w-[45%] border-b-[1px] py-2 items-center",
        highlight ? "text-brand-600" : "",
      )}
      {...rest}
    >
      <div className="flex w-full flex-row items-center justify-between text-center">
        <div className="mt-1 text-md self-baseline">{label}</div>
        <div className="flex max-w-[50%] flex-wrap gap-x-1 justify-end font-semibold">
          {Array.isArray(value) ?
            uniq(
              value.map((val) =>
                typeof val === "object" ? JSON.stringify(val) : startCase(toLower(val)),
              ),
            ).map((val, index) => (
              <div
                key={index}
                className="text-sm leading-2 whitespace-nowrap overflow-hidden text-ellipsis"
              >
                {index > 0 && <span> • </span>}
                {val}
              </div>
            ))
          : <div className="text-sm">{String(value)}</div>}
        </div>
      </div>
    </div>
  );
}
