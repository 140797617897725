import ConnectionCard from "@/components/entities/ConnectionCard";
import ProfileBubble from "@/components/sections/ProfileBubble";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import ExpandoList from "@/components/ui/ExpandoList";
import { MutualConnectionsData, useEntityStore } from "@/stores/entityStore";
import { EntityUIType, MutualConnectionsStatus, ProfilePageSection } from "@/types";
import { useStore } from "@nanostores/react";
import { twJoin } from "tailwind-merge";

type Props = { wide?: boolean };

export default withErrorBoundary(function MutualConnections(props: Props) {
  const entityStore = useEntityStore();
  const mutualConnections = useStore(entityStore.mutualConnections);

  const status = useStore(entityStore.mutualConnectionsStatus);
  if (status === MutualConnectionsStatus.Pending || status === MutualConnectionsStatus.Skipped) {
    return;
  }

  const connectionList = Object.values(mutualConnections);

  const columns = props.wide ? 3 : 2;

  return (
    <ProfileBubble
      title={`Mutual Connections • ${String(connectionList.length)}`}
      section={ProfilePageSection.MutualConnections}
    >
      {connectionList.length === 0 && (
        <div className="text-gray-500">No mutual connections found</div>
      )}
      {connectionList.length > 0 && (
        <div className="flex flex-col gap-4 overflow-hidden">
          <ExpandoList
            items={connectionList}
            limit={6}
            columns={columns}
            divideExpander
            seeMoreClassName="pt-0 text-center"
            className={twJoin(
              `list-inside text-gray-700 gap-2 flex flex-col sm:grid`,
              columns == 2 ? "sm:grid-cols-2" : "sm:grid-cols-3",
            )}
            renderItem={(connection: MutualConnectionsData, index) => (
              <ConnectionCard
                key={connection?.data?.linkedinUrl || index}
                connection={connection}
              />
            )}
          />
        </div>
      )}
    </ProfileBubble>
  );
});
