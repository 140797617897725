// --- workflows and their inputs

import type { AuthoredMediaSourceData } from "@/hatchet/steps/createAuthoredMediaSources";
import type { NamedPerson } from "@/hatchet/steps/personNameResolve";
import type { AuthoredMediaSourceIdByDomain } from "@/hatchet/steps/searchAuthoredMediaSources";
import type { SocialAccountData, SocialPostsData } from "@/hatchet/steps/socialMedia";
import { assertUnreachable } from "@/lib/utils";
import {
  Education,
  FactValuePartial,
  Link,
  LinkedinCommonProfile,
  PrivateAttributeMutualConnection,
  ProtoRelationship,
  WorkExperience,
} from "@/types";
import { AuthoredMediaDetectionResults } from "@/utils/authoredMediaUtils";
import { AuthoredMediaByDomain } from "@/utils/scrapeSelectedAuthoredMedia";

export const DELAYED_REFRESH_WINDOW = 1000 * 60 * 5; // 5 minutes

// remember to update hatchet/worker.ts if you add a new workflow
export enum HatchetWorkflow {
  PersonLoader = "person-loader",
  MiniPersonLoader = "mini-person-loader",
  RegenerateEntity = "regen-entity",
  RefreshSocialMedia = "refresh-social-media",
  RegenerateOverview = "regenerate-overview",
  CompanyLoader = "company-loader",
  MiniCompanyLoader = "mini-company-loader",
  PrepareContacts = "prepare-contacts",
  UpdatePerson = "update-person",
  UpdateCompany = "update-company",
  EmailResolve = "email-resolve",
  DogfoodReminder = "dogfood-reminder",
  MutualConnections = "mutual-connections",
  AggregateMutuals = "aggregate-mutuals",
  AuthoredMedia = "authored-media",
  SyncListProfiles = "sync-list-profiles",
  SyncMarketingEmails = "sync-marketing-emails",
  SyncLIConnections = "sync-li-connections",
  RedoBrandedBlurb = "redo-branded-blurb",
}

export const HatchetWorkflowDescriptions: Record<HatchetWorkflow, string> = {
  [HatchetWorkflow.PersonLoader]: "Generate complete profile of a person",
  [HatchetWorkflow.MiniPersonLoader]: "Generate mini profile of a person for searching",
  [HatchetWorkflow.RegenerateEntity]: "Regenerate entity name/description from URL",
  [HatchetWorkflow.RefreshSocialMedia]: "Refresh social media data for a person's profile",
  [HatchetWorkflow.RegenerateOverview]: "Recreate the summary overview of the entity",
  [HatchetWorkflow.CompanyLoader]: "Generate complete profile of a company",
  [HatchetWorkflow.MiniCompanyLoader]: "Generate mini profile of a company for searching",
  [HatchetWorkflow.PrepareContacts]: "Prepare user's contacts for querying",
  [HatchetWorkflow.UpdatePerson]: "Update a person with latest info",
  [HatchetWorkflow.UpdateCompany]: "Update a company with latest info",
  [HatchetWorkflow.EmailResolve]: "Resolves a batch of emails (to email resolution objects)",
  [HatchetWorkflow.DogfoodReminder]: "Reminds users to review profiles",
  [HatchetWorkflow.MutualConnections]:
    "Update mutual connections of a user and a specific LinkedIn profile",
  [HatchetWorkflow.AggregateMutuals]:
    "Sync employee relationships for a company -- built from mutuals",
  [HatchetWorkflow.AuthoredMedia]: "Discover and summarize articles written by a user",
  [HatchetWorkflow.SyncListProfiles]: "Sync entity profiles for a list",
  [HatchetWorkflow.SyncMarketingEmails]: "Sync user emails to email marketing service",
  [HatchetWorkflow.SyncLIConnections]: "Sync LinkedIn connections for a user",
  [HatchetWorkflow.RedoBrandedBlurb]: "Regenerate branded blurb for a company",
};

export interface HatchetWorkflowInputs {
  [HatchetWorkflow.UpdatePerson]: EntityWorkflowInput;
  [HatchetWorkflow.UpdateCompany]: EntityWorkflowInput;
  [HatchetWorkflow.PersonLoader]: EntityWorkflowInput;
  [HatchetWorkflow.MiniPersonLoader]: EntityWorkflowInput;
  [HatchetWorkflow.CompanyLoader]: EntityWorkflowInput;
  [HatchetWorkflow.MiniCompanyLoader]: EntityWorkflowInput;
  [HatchetWorkflow.RegenerateOverview]: RegenerateOverviewWorkflowInput;
  [HatchetWorkflow.RegenerateEntity]: EntityWorkflowInput;
  [HatchetWorkflow.RefreshSocialMedia]: EntityWorkflowInput;
  [HatchetWorkflow.PrepareContacts]: PrepareContactsWorkflowInput;
  [HatchetWorkflow.EmailResolve]: EmailResolveWorkflowInput;
  [HatchetWorkflow.DogfoodReminder]: WorkflowInput;
  [HatchetWorkflow.MutualConnections]: EntityWorkflowInput;
  [HatchetWorkflow.AggregateMutuals]: AggregateMutualsWorkflowInput;
  [HatchetWorkflow.AuthoredMedia]: EntityWorkflowInput;
  [HatchetWorkflow.SyncListProfiles]: WorkflowInput;
  [HatchetWorkflow.SyncMarketingEmails]: WorkflowInput;
  [HatchetWorkflow.SyncLIConnections]: SyncLIConnectionsWorkflowInput;
  [HatchetWorkflow.RedoBrandedBlurb]: EntityWorkflowInput;
}

export type WorkflowInput = {
  userId?: string;
  reason: string;
  delayUntil?: string;
  scheduledId?: string;
  disableChildWorkflows?: boolean;
  skipCache?: boolean;
};

export type PrepareContactsWorkflowInput = WorkflowInput & {
  tokenId: string;
  userId: string;
};

export type EmailResolveWorkflowInput = WorkflowInput & {
  emails: string[];
};

export type EntityWorkflowInput = WorkflowInput & {
  userId: string;
  entityId: string;
};

export type AggregateMutualsWorkflowInput = EntityWorkflowInput & {
  mutualConnections: PrivateAttributeMutualConnection[];
};

export type RegenerateOverviewWorkflowInput = EntityWorkflowInput & {
  regenerate?: boolean;
};

export type MeetingWorkflowInput = WorkflowInput & {
  meetingId: string;
  userId: string;
};

export type SyncLIConnectionsWorkflowInput = WorkflowInput & {
  resync?: boolean;
};

export const isEntityWorkflowInput = (input: WorkflowInput): input is EntityWorkflowInput => {
  return (input as EntityWorkflowInput).entityId !== undefined;
};

export const isMeetingWorkflowInput = (input: WorkflowInput): input is MeetingWorkflowInput => {
  return (input as MeetingWorkflowInput).meetingId !== undefined;
};

// --- steps and their outputs

export enum WorkflowStep {
  test = "test",
  recordRunStart = "record-run-start",
  recordRunFinish = "record-run-finish",
  entityResolve = "entity-resolve",
  nameResolve = "name-resolve",
  mixrankResolve = "mixrank-resolve",
  searchCompanyWebsite = "search-company-website",
  findCompanyBlog = "find-company-blog",
  extendedExperience = "extended-experience",
  generateFilter = "generate-filter",
  generateQueries = "generate-queries",
  performSearches = "perform-searches",
  filterSearches = "filter-searches",
  socialMedia = "social-media",
  scrapeAndFilter = "scrape-and-filter",
  scrapeCompanyWebsiteLinks = "scrape-company-brand-links",
  summarizeSources = "summarize-sources",
  generateOverview = "generate-overview",
  companyUnstructuredExtract = "company-unstructured-extract",
  generateCompanyBrandedBlurb = "generate-company-branded-blurb",
  identifyKeyPeople = "identify-key-people",
  entityFinished = "entity-finished",
  storeContacts = "store-contacts",
  resolveEmails = "resolve-emails",
  sendDogfoodReminder = "send-dogfood-reminder",
  companyProfileFinder = "company-profile-finder",
  personProfileFinder = "person-profile-finder",
  structuredExtract = "structured-extract",
  problemDetection = "problem-detection",
  mutualConnections = "mutual-connections",
  aggregateMutuals = "aggregate-mutuals",
  filterSourcesForAuthoredMedia = "filter-sources-for-authored-media",
  createAuthoredMediaSources = "create-authored-media-sources",
  searchAuthoredMediaSources = "search-authored-media-sources",
  scrapeAuthoredMedia = "scrape-authored-media",
  syncListProfiles = "sync-list-profiles",
  syncMarketingEmails = "sync-marketing-emails",
  syncLIConnections = "sync-li-connections",
  vcInvestmentFinder = "vc-investment-finder",
  companyIndexGenerator = "company-index-generator",
}

export const workflowKey = (workflowId: HatchetWorkflow, input: unknown): string => {
  switch (workflowId) {
    case HatchetWorkflow.UpdatePerson:
    case HatchetWorkflow.UpdateCompany:
    case HatchetWorkflow.PersonLoader:
    case HatchetWorkflow.CompanyLoader:
    case HatchetWorkflow.MiniPersonLoader:
    case HatchetWorkflow.MiniCompanyLoader:
    case HatchetWorkflow.RegenerateOverview:
    case HatchetWorkflow.RegenerateEntity:
    case HatchetWorkflow.RefreshSocialMedia:
    case HatchetWorkflow.MutualConnections:
    case HatchetWorkflow.AggregateMutuals:
    case HatchetWorkflow.AuthoredMedia:
    case HatchetWorkflow.RedoBrandedBlurb:
      return (input as EntityWorkflowInput).entityId;
    case HatchetWorkflow.PrepareContacts:
      return (input as PrepareContactsWorkflowInput).tokenId;
    case HatchetWorkflow.EmailResolve:
      return (input as EmailResolveWorkflowInput).emails.join(",");
    case HatchetWorkflow.DogfoodReminder:
      return `dogfood-reminder-${(input as WorkflowInput).reason}`;
    case HatchetWorkflow.SyncListProfiles:
      return `sync-list-profiles-${new Date(Math.floor(Date.now() / 60000) * 60000).toISOString()}`;
    case HatchetWorkflow.SyncLIConnections:
      return (input as WorkflowInput).userId || "nil";
    case HatchetWorkflow.SyncMarketingEmails:
      const { userId } = input as WorkflowInput;
      return `sync-marketing-emails${userId ? `-${userId}` : ""}`;
  }
  return assertUnreachable(workflowId);
};

// NOTE: avoid circular dependencies by not having this type refer to types inside step files
export interface WorkflowStepOutputs {
  [WorkflowStep.entityResolve]: {
    name: string;
    url: string;
    profile?: LinkedinCommonProfile["profile"];
  };
  [WorkflowStep.nameResolve]: {
    primaryName: string;
    names: EntityName[];
  };
  [WorkflowStep.extendedExperience]: {
    experience: WorkExperience[];
    education: Education[];
  };
  [WorkflowStep.mutualConnections]: {
    mutualConnections: PrivateAttributeMutualConnection[];
  };
  [WorkflowStep.aggregateMutuals]: {
    created: number;
    updated: number;
    deleted: number;
    newTotal: number;
    formerTotal: number;
  };
  [WorkflowStep.searchCompanyWebsite]: {
    links: LinkType[];
  };
  [WorkflowStep.findCompanyBlog]: {
    blogLink?: string;
    authoredMediaDetectionResults: AuthoredMediaDetectionResults;
  };
  [WorkflowStep.generateFilter]: {
    positive: string[];
    negative: string[];
    unsure: string[];
  };
  [WorkflowStep.generateQueries]: {
    queries: string[];
  };
  [WorkflowStep.performSearches]: {
    links: SearchLinkType[];
  };
  [WorkflowStep.filterSearches]: {
    links: SearchLinkType[];
    skippedLinks: string[];
    disregardReasons: Record<string, string>;
    keepReasons: Record<string, string>;
  };
  [WorkflowStep.socialMedia]: {
    socialAccounts?: SocialAccountData[];
    socialPosts?: SocialPostsData[];
  };
  [WorkflowStep.scrapeAndFilter]: {
    crawled: string[];
    selected: string[];
    dropped: string[];
    neverCrawled: string[];
  };
  [WorkflowStep.scrapeCompanyWebsiteLinks]: {
    crawled: string[];
    skipped: string[];
    errored: string[];
  };
  [WorkflowStep.summarizeSources]: {
    summarized: Record<string, string[]>;
  };
  [WorkflowStep.generateOverview]: {
    overview: string[];
  };
  [WorkflowStep.companyUnstructuredExtract]: {
    facts: FactValuePartial;
  };
  [WorkflowStep.generateCompanyBrandedBlurb]: {
    blurb: string;
    urls: string[];
  };
  [WorkflowStep.entityFinished]: { entityId: string };
  [WorkflowStep.storeContacts]: {
    contactsUpdated: number;
    userId: string;
    tokenId: string;
    status: string;
  };
  [WorkflowStep.resolveEmails]: {
    results: Record<string, string>;
  };
  [WorkflowStep.sendDogfoodReminder]: {
    successfulWebhook: boolean;
  };
  [WorkflowStep.companyProfileFinder]: {
    profiles: string[];
  };
  [WorkflowStep.personProfileFinder]: {
    profiles: string[];
  };
  [WorkflowStep.mixrankResolve]: {
    facts: FactValuePartial;
  };
  [WorkflowStep.structuredExtract]: {
    facts: FactValuePartial;
  };
  [WorkflowStep.identifyKeyPeople]: {
    relationships: { key: string; type: string }[];
  };
  [WorkflowStep.vcInvestmentFinder]: {
    investments: ProtoRelationship<"invested-in">[];
  };
  [WorkflowStep.problemDetection]: {};
  [WorkflowStep.filterSourcesForAuthoredMedia]: {
    authoredMedia: AuthoredMediaDetectionResults;
  };
  [WorkflowStep.createAuthoredMediaSources]: {
    newAuthoredMediaSources: AuthoredMediaSourceData[];
    existingAuthoredMediaSources: AuthoredMediaSourceData[];
  };
  [WorkflowStep.searchAuthoredMediaSources]: {
    links: (SearchLinkType | Link)[];
    authoredMediaSourcesIdByDomain: AuthoredMediaSourceIdByDomain;
  };
  [WorkflowStep.scrapeAuthoredMedia]: ScrapeAuthoredMediaResponse;
  [WorkflowStep.syncListProfiles]: {
    listsSynced: number;
  };
  [WorkflowStep.syncMarketingEmails]: {
    emailsSynced: number;
  };
  [WorkflowStep.syncLIConnections]: {
    name: string;
    connectionsSynced: number;
    hasMore?: boolean;
  };
  [WorkflowStep.companyIndexGenerator]: {
    geos: string[];
    sectors: string[];
  };
}

export type SearchLinkType = {
  rank: number;
  title: string;
  url: string;
  description: string;
  source: string;
};

export type LinkType = {
  url: string;
  title: string;
};

export type ScrapeAuthoredMediaResponse = {
  crawled: string[];
  authoredMedia: AuthoredMediaByDomain;
};

export type EntityName = NamedPerson | string;
