import EditableFact from "@/components/entities/EditableFact";
import DelimitedList from "@/components/ui/DelimitedList";
import { useDevTools } from "@/hooks/useUIStore";
import { ageFromBirthYear, pluralize } from "@/lib/stringUtils";
import eventTracker from "@/lib/trackers/eventTracker";
import { useEntityStore } from "@/stores/entityStore";
import { uiStore } from "@/stores/uiStore";
import { CompanyFact, EntityType, PersonFact, SocialAccount } from "@/types";
import { useStore } from "@nanostores/react";
import React, { ReactNode, useState } from "react";
import { toast } from "react-toastify";

const FactsList = () => {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const aliases = useStore(entityStore.aliases);
  const facts = useStore(entityStore.facts);
  const canEditAttrs = useStore(entityStore.canEdit);
  const details: (ReactNode | string)[] = [];
  const user = useStore(uiStore.user);
  const isAdmin = useDevTools();
  const socials = useStore(entityStore.socials);

  if (entity.type === EntityType.Person) {
    if (facts[PersonFact.Pronouns]?.value || canEditAttrs) {
      details.push(
        <EditableFact
          factType={PersonFact.Pronouns}
          canEdit={canEditAttrs}
          onEdit={() =>
            entityStore.showFactEditModal.set({
              type: PersonFact.Pronouns,
              currentValue: facts[PersonFact.Pronouns]?.value,
            })
          }
        >
          {editableText("Pronouns", facts[PersonFact.Pronouns]?.value, canEditAttrs)}
        </EditableFact>,
      );
    }
    const birthYear = facts[PersonFact.Birthyear]?.value;
    if (birthYear) {
      const approxAge = ageFromBirthYear(birthYear);
      details.push(
        <EditableFact
          factType={PersonFact.Birthyear}
          canEdit={canEditAttrs}
          onEdit={() =>
            entityStore.showFactEditModal.set({
              type: PersonFact.Birthyear,
              currentValue: `${birthYear}`,
            })
          }
        >
          Approx Age: {approxAge}
        </EditableFact>,
      );
    }

    if (facts[PersonFact.AltNames]?.value) {
      details.push(<Names names={facts[PersonFact.AltNames]?.value} loggedIn={!!user} />);
    }
  }

  if (entity.type === EntityType.Company) {
    if (aliases.length > 0) {
      details.push(<Domains aliases={aliases} socials={socials} />);
    }
    if (facts[CompanyFact.FoundedYear]?.value) {
      details.push(`Founded: ${facts[CompanyFact.FoundedYear]?.value}`);
    }
    if (facts[CompanyFact.Headquarters]?.value) {
      details.push(`Headquarters: ${facts[CompanyFact.Headquarters]?.value}`);
    }
  }

  const cleanedDetails = details.filter((detail) => !!detail);

  return (
    <div className="flex flex-col text-13pt justify-start text-gray-400 max-w-full overflow-hidden">
      <div className="inline-block">
        <DelimitedList list={cleanedDetails} spacing="mx-1" itemClass="inline" />
      </div>
      {isAdmin &&
        entity.type === EntityType.Person &&
        aliases.find((alias) => alias.includes("@")) && (
          <Emails aliases={aliases} loggedIn={!!user} />
        )}
    </div>
  );
};

interface ExpandableListProps {
  items: string[];
  label?: string;
  threshold?: number;
  unit?: string;
  onExpand?: () => void;
}

const ExpandableList = ({
  items,
  label,
  threshold = 2,
  onItemClick,
  unit = "Item",
  onExpand,
}: ExpandableListProps & {
  onItemClick?: (item: string) => void;
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    if (onExpand) {
      onExpand();
    }
    setExpanded(true);
  };

  const renderItems = (itemsList: string[]) => {
    return itemsList.map((item, index, arr) => {
      const isLast = index === arr.length - 1;
      const clickable = !!onItemClick;

      return (
        <React.Fragment key={item}>
          {clickable ?
            <button className="hover:underline" onClick={() => onItemClick?.(item)}>
              {item}
            </button>
          : item}
          {!isLast && (index === arr.length - 2 ? " " + "and " : ", ")}
        </React.Fragment>
      );
    });
  };

  if (items.length === 0) return null;

  if (threshold === 0 && !expanded) {
    return (
      <button onClick={handleExpand} className="w-min whitespace-nowrap">
        <p className="inline hover:underline">{pluralize(items.length, unit)}</p>
      </button>
    );
  }

  if (expanded) {
    return (
      <React.Fragment>
        {label && `${label}: `}
        {renderItems(items)}
      </React.Fragment>
    );
  }

  if (items.length <= threshold) {
    return (
      <React.Fragment>
        {label && `${label}: `}
        {renderItems(items)}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {label && `${label}: `}
      {renderItems(items.slice(0, threshold))}
      <button onClick={handleExpand} className="ml-1">
        {threshold > 1 ? "," : ""} and{" "}
        <p className="inline underline hover:text-gray-800">{items.length - threshold} more</p>
      </button>
    </React.Fragment>
  );
};

const Domains = ({ aliases, socials }: { aliases: string[]; socials: SocialAccount[] }) => {
  const domains = aliases.filter(
    (alias) =>
      alias.includes(".") &&
      !alias.includes(" ") &&
      !alias.includes("@") &&
      !socials.some((social) => social.url.includes(alias)),
  );

  return <ExpandableList items={domains} label="Other Domains" unit="Other Domain" threshold={0} />;
};

const Emails = ({ aliases, loggedIn }: { aliases: string[]; loggedIn: boolean }) => {
  const emails = aliases.filter((alias) => alias.includes("@"));

  const handleEmailClick = async (email: string) => {
    eventTracker.capture("copy-email", { email });
    await navigator.clipboard.writeText(email);
    toast.success("Email copied to clipboard");
  };

  const onExpand =
    !loggedIn ?
      () => {
        if (!loggedIn) {
          window.open("/auth/signin", "_blank");
        }
      }
    : undefined;

  return (
    <div className="whitespace-pre-wrap max-w-full inline">
      <ExpandableList
        items={emails}
        label="E-mail"
        threshold={0}
        onExpand={onExpand}
        onItemClick={handleEmailClick}
        unit="Email"
      />
    </div>
  );
};

const Names = ({ names, loggedIn }: { names?: string[]; loggedIn: boolean }) => {
  if (!names) return null;

  const onExpand =
    !loggedIn ?
      () => {
        if (!loggedIn) {
          window.open("/auth/signin", "_blank");
        }
      }
    : undefined;

  return (
    <ExpandableList
      items={names}
      label="Alternate names"
      threshold={0}
      unit="Alternate Name"
      onExpand={onExpand}
    />
  );
};

const editableText = (label: string, value: string | undefined, canEdit: boolean) => {
  if (value) return `${label}: ${value}`;
  else if (canEdit) return `(Set ${label})`;
  else return "";
};

export default FactsList;
