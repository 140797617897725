import Message from "@/components/messages/Message";
import { messageStore } from "@/stores/messageStore";
import { ChatRole } from "@/types";
import { useStore } from "@nanostores/react";

export default function InProgress() {
  const inProgress = useStore(messageStore.inProgress);
  if (!inProgress) return null;
  return <MessageLoading />;
}

const MessageLoading = () => {
  const interrupt = () => {
    messageStore.interrupt();
  };
  const partialMessage = useStore(messageStore.partialMessage);

  return (
    <>
      {partialMessage ?
        <Message
          pos={{ i: 0, len: 1 }}
          message={{ role: ChatRole.Assistant, content: partialMessage }}
        />
      : <div className={`message px-8 max-w-3xl flex items-center`}>
          <div className="ml-4 dot-flashing" />
        </div>
      }
      {/* <ClearButton className="ml-8 text-xs text-gray-500" onClick={interrupt}>
        Abort?
      </ClearButton> */}
    </>
  );
};
