"use client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import useAutosizeTextArea from "@/components/hooks/useAutosizeTextArea";
import { messageStore } from "@/stores/messageStore";
import { useStore } from "@nanostores/react";
import ReactTextareaAutocomplete, { TriggerType } from "@webscopeio/react-textarea-autocomplete";
import { useRouter as usePagesRouter } from "next/compat/router";
import { useRouter } from "next/navigation";

export default function ChatInput({
  createNewSearchOnSubmit,
  variant = "default",
}: {
  createNewSearchOnSubmit?: boolean;
  variant?: "default" | "entity";
}) {
  const rtaRef = useRef<ReactTextareaAutocomplete<string> | null>(null);
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const inProgress = useStore(messageStore.inProgress);
  const currentMessage = useStore(messageStore.currentMessage);
  const pagesRouter = usePagesRouter();
  const appRouter = useRouter();

  const textAreaHeight = variant === "entity" ? 30 : 100;

  useEffect(() => {
    if (currentMessage) {
      ref.current?.focus();
    }
  }, [currentMessage]);

  useEffect(() => {
    //ref.current?.focus();
  }, []);

  const send = useCallback(() => {
    if (!ref.current || !rtaRef.current) return;
    void messageStore.sendCurrentMessage(true);
    if (createNewSearchOnSubmit) {
      void (pagesRouter || appRouter)?.push("/discover2/search-id-will-go-here");
    }
  }, [pagesRouter, appRouter]);

  const trigger: TriggerType<string> = useMemo(
    () => ({
      "@": {
        dataProvider: (token: string) => {
          return [];
        },
        component: FileRow,
        output: (entity: string) => {
          return entity;
        },
      },
    }),
    [],
  );

  const loadingComponent = useCallback(() => <div>Loading...</div>, []);

  useAutosizeTextArea(ref.current, currentMessage?.content || "", textAreaHeight);
  const firstMessage = useStore(messageStore.messages).length == 0;

  const placeholder =
    inProgress ? "Sending..."
    : firstMessage ? "Describe what you want to find"
    : "Type your reply";

  return (
    <div className="">
      <div
        className={`${variant === "entity" ? "border border-brand-300 flex items-center p-1" : "bg-gray-100"} rounded relative`}
      >
        <ReactTextareaAutocomplete<string>
          value={currentMessage?.content || ""}
          onChange={(e) => {
            messageStore.setCurrentMessageContent(ref.current?.value || "");
          }}
          ref={rtaRef}
          containerClassName="flex-1"
          trigger={trigger}
          placeholder={placeholder}
          loadingComponent={loadingComponent}
          disabled={inProgress}
          innerRef={(textarea: HTMLTextAreaElement) => (ref.current = textarea)}
          className={`bg-white w-full focus:ring-0 focus-visible:ring-0 rounded text-sm ${variant === "entity" ? "resize-none border-none" : `h-[${textAreaHeight}px] min-h-[${textAreaHeight}px] sm:min-h-[3rem] border border-brand-300 p-4`}`}
          dropdownClassName="bg-white shadow-md rounded absolute z-10"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              send();
            }
          }}
        />
        {variant === "entity" && (
          <button
            onClick={send}
            className="bg-brand-600 text-white h-10 w-[107px] p-2 rounded disabled:opacity-50 flex-shrink-0"
          >
            Ask AI
          </button>
        )}
      </div>
    </div>
  );
}

const FileRow = ({ selected, entity }: { selected: boolean; entity: string }) => (
  <div className={`p-2 ${selected ? "bg-blue-200" : ""}`}>{entity}</div>
);
