import { formatDollarAmount, formatNumber, toAbbreviatedDollarAmount } from "@/lib/stringUtils";
import { CompanyFact, FactsPick, FactType, FactValueSet } from "@/types";
import { format, isValid, parseISO } from "date-fns";
import { groupBy } from "lodash";

export function getLabel(type: FactType) {
  switch (type) {
    case CompanyFact.Employees:
      return "Employees";
    case CompanyFact.FoundedYear:
      return "Founded Year";
    case CompanyFact.Headquarters:
      return "Headquarters";
    case CompanyFact.CompanySize:
      return "Size";
    case CompanyFact.CompanyType:
      return "Type";
    case CompanyFact.State:
      return "Status";
    case CompanyFact.TotalFunding:
      return "Total Funding";
    case CompanyFact.NumExits:
      return "Exits";
    case CompanyFact.NumFundsRaised:
      return "Funds Raised";
    case CompanyFact.NumInvestments:
      return "Investments";
    case CompanyFact.TotalFundsRaisedAmt:
      return "Total Funds Raised";
    case CompanyFact.InvestmentRounds:
      return "Investment Rounds";
    case CompanyFact.DoLeadInvestments:
      return "Lead Investor";
    case CompanyFact.RecentInvestments:
      return "Recent Investments";
    case CompanyFact.FamousInvestments:
      return "Famous Investments";
    case CompanyFact.InvestmentSectors:
      return "Sectors";
    case CompanyFact.InvestmentGeographies:
      return "Geographies";
    case CompanyFact.LatestFundSize:
      return "Latest Fund Size";
    case CompanyFact.LatestFundDate:
      return "Latest Fund Date";
    case CompanyFact.AssetsUnderManagement:
      return "Assets Under Management";
    case CompanyFact.LatestRound:
      return "Latest Round";
  }
  return type;
}

export function formatValue<T extends FactType>(type: T, value: FactValueSet[T]) {
  switch (type) {
    case CompanyFact.InvestmentRounds:
    case CompanyFact.InvestmentGeographies:
      return value as string[];
    case CompanyFact.InvestmentSectors:
      return (value as string[]).filter((s) => s.length < 25).slice(0, 5);
    case CompanyFact.RecentInvestments:
    case CompanyFact.FamousInvestments:
      return (value as string[]).slice(0, 3);
    case CompanyFact.LatestFundSize:
    case CompanyFact.AssetsUnderManagement:
      return formatDollarAmount(value as number);
    case CompanyFact.LatestFundDate:
      const date = parseISO(value as string);
      if (!isValid(date)) {
        return "";
      }
      return format(date, "MMMM d, yyyy");
    case CompanyFact.DoLeadInvestments:
      return (
        value === undefined ? ""
        : value ? "Yes"
        : "No"
      );
    case CompanyFact.State:
      return formatCompanyState(value as FactValueSet[typeof CompanyFact.State]);
    case CompanyFact.Employees:
      return formatNumber(value as number);
    case CompanyFact.LatestRound:
      const typedValue = value as FactValueSet[typeof CompanyFact.LatestRound];
      const amount = Number(typedValue.moneyRaised);
      const details = [
        !typedValue.fundingType?.toLowerCase().includes("unknown") ?
          typedValue.fundingType
        : undefined,
        toAbbreviatedDollarAmount(amount),
      ].filter(Boolean);
      return details;
    default:
      return value;
  }
}

function formatCompanyState(state: FactValueSet[typeof CompanyFact.State]) {
  switch (state) {
    case "ACTIVE":
      return "Active";
    case "ACQUIRED":
      return "Acquired";
    case "POSSIBLY_ACQUIRED":
      return "Possibly Acquired";
    case "INACTIVE":
      return "Inactive";
    case "POSSIBLY_INACTIVE":
      return "Unsure";
    default: {
      const _exhaustiveCheck: never = state;
      throw new Error(`Unhandled variant: ${_exhaustiveCheck}`);
    }
  }
  return state;
}

export function aggregateHeadcount(
  historicalHeadcount: { date: string; headcount: number }[] | undefined,
) {
  if (!historicalHeadcount) return [];
  const groupedByYear = groupBy(historicalHeadcount, (item) => new Date(item.date).getFullYear());
  return Object.entries(groupedByYear)
    .map(([year, data]) => ({
      date: year,
      headcount: Math.max(...data.map((d) => d.headcount)),
    }))
    .sort((a, b) => Number(a.date) - Number(b.date));
}

export function getHeadcountFromFacts(facts: FactsPick) {
  const headcount = facts[CompanyFact.Employees]?.value;

  const aggregatedHeadcount = aggregateHeadcount(facts[CompanyFact.HistoricalHeadcount]?.value);
  const mostRecentHeadcount =
    aggregatedHeadcount.length > 0 ?
      aggregatedHeadcount[aggregatedHeadcount.length - 1]?.headcount
    : 0;
  return headcount || mostRecentHeadcount || 0;
}
