import moment from "moment";
import { Fragment, ReactNode } from "react";

import EditableFact from "@/components/entities/EditableFact";
import DelimitedList from "@/components/ui/DelimitedList";
import { prettyUrl } from "@/lib/utils";
import { useEntityStore } from "@/stores/entityStore";
import { findAttribute } from "@/stores/utils";
import { Attribute, AttributeType, CompanyFact, EntityType, PersonFact } from "@/types";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";

type Props = {
  entity: Entity;
  attributes: Attribute[];
  skipLastUpdated?: boolean;
};

export function shouldShowCompanyWebsite(website?: string) {
  return website && !website.includes("linkedin.com/") && !website.includes("distill://");
}

export default function CompanySubtitle(props: Props) {
  const entityStore = useEntityStore();
  const facts = useStore(entityStore.facts);
  const canEditAttrs = useStore(entityStore.canEdit);
  const { entity, attributes, skipLastUpdated } = props;

  const subtitles: (ReactNode | string)[] = [];
  const linkedinProfile = findAttribute(attributes, AttributeType.LinkedinCompanyProfile);

  const website = facts[CompanyFact.Website]?.value || entity.url;
  if (shouldShowCompanyWebsite(website)) {
    subtitles.push(
      <a href={website} className="text-blue-500 hover:text-blue-700">
        {prettyUrl(website)}
      </a>,
    );
  }

  const location =
    facts[CompanyFact.Headquarters]?.value || linkedinProfile?.value.profile.location;
  if (location) {
    subtitles.push(
      <EditableFact
        factType={CompanyFact.Headquarters}
        canEdit={canEditAttrs}
        onEdit={() =>
          entityStore.showFactEditModal.set({
            type: CompanyFact.Headquarters,
            currentValue: location,
          })
        }
      >
        {location}
      </EditableFact>,
    );
  }

  if (entity.generatedAt && !skipLastUpdated)
    subtitles.push("Last Updated: " + moment(entity.generatedAt).fromNow());

  return (
    <div className="text-13pt md:text-sm flex flex-wrap overflow-hidden max-w-full">
      <DelimitedList
        list={subtitles.filter(Boolean)}
        spacing="mx-1"
        itemClass="whitespace-nowrap overflow-hidden text-ellipsis truncate"
      />
    </div>
  );
}
