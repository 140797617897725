// SidebarContext.tsx
import EntitySidebar, { EntitySidebarProps } from "@/components/entities/EntitySidebar";
import { AddEntityToList, AddEntityToListProps } from "@/components/lists/AddEntityToList";
import InviteListUsers from "@/components/lists/InviteListUsers";
import AssociateAliasSearch, {
  AssociateAliasSearchProps,
} from "@/components/modals/AssociateAliasSearch";
import RightPanel from "@/components/modals/RightPanel";
import React, { createContext, useContext, useState } from "react";

/**
 * A provider for the sidebar.
 *
 * Alright so I know this looks scary but this is actually super easy to extend.
 *
 * To add a new sidebar, you need to:
 * 1. Add a new variant to the VariantType enum
 * 2. Create a new component that extends React.FC and has the appropriate props
 * 3. Add the new component's props to the VariantPropsMap
 * 4. Add the new component to the variantComponentMap
 * 5. Use the useSidebar hook to open the sidebar. It will tell you exactly what props to pass in bcs typescript magic.
 *
 * That's it!
 */

export enum SidebarVariant {
  Association = "association",
  AddEntityToList = "addEntityToList",
  Entity = "entity",
  InviteListUsers = "inviteListUsers",
  // Add other variants as needed
}

type BaseProps = {
  title?: string;
};

type VariantPropsMap = {
  [K in SidebarVariant]: BaseProps;
} & {
  [SidebarVariant.Association]: AssociateAliasSearchProps;
  [SidebarVariant.AddEntityToList]: AddEntityToListProps;
  [SidebarVariant.Entity]: EntitySidebarProps;
  // Add other variant props
};

export const variantComponentMap: {
  [K in SidebarVariant]: React.ComponentType<VariantPropsMap[K]>;
} = {
  [SidebarVariant.Association]: AssociateAliasSearch,
  [SidebarVariant.AddEntityToList]: AddEntityToList,
  [SidebarVariant.Entity]: EntitySidebar,
  [SidebarVariant.InviteListUsers]: InviteListUsers,
  // Add other variant to component mappings
};

interface SidebarContextProps {
  openSidebar: <V extends SidebarVariant>(variant: V, props: VariantPropsMap[V]) => void;
  closeSidebar: () => void;
}

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

export const SidebarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [variant, setVariant] = useState<SidebarVariant>();
  const [props, setProps] = useState<VariantPropsMap[SidebarVariant] & { title?: string }>();

  const openSidebar = <V extends SidebarVariant>(variant: V, props: VariantPropsMap[V]) => {
    setVariant(variant);
    setProps(props);
    setOpen(true);
  };

  const closeSidebar = () => {
    setOpen(false);
  };

  const content =
    variant ?
      React.createElement(
        variantComponentMap[variant] as React.ComponentType<VariantPropsMap[typeof variant]>,
        {
          ...(props as VariantPropsMap[typeof variant]),
        },
      )
    : null;

  const className = variant === SidebarVariant.Entity ? "max-w-xl" : undefined;

  return (
    <SidebarContext.Provider value={{ openSidebar, closeSidebar }}>
      {children}
      <RightPanel open={open} close={closeSidebar} title={props?.title || ""} className={className}>
        {content}
      </RightPanel>
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (context === undefined) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};
