/* eslint-disable @next/next/no-img-element */
import CompanySubtitle from "@/components/companies/CompanySubtitle";
import ProfilePopoverMenu from "@/components/entities/ProfilePopoverMenu";
import EntityImageEditModal from "@/components/modals/EntityImageEditModal";
import FactsList from "@/components/people/FactsList";
import PersonSubtitle from "@/components/people/PersonSubtitle";
import SocialButtons from "@/components/people/SocialButtons";
import { useSidebar } from "@/components/providers/SidebarProvider";
import EntityHighlights from "@/components/sections/EntityHighlights";
import EntityTabs from "@/components/sections/EntityTabs";
import ProfileBubble from "@/components/sections/ProfileBubble";
import SupportButton from "@/components/support/SupportButton";
import ConditionalLink from "@/components/ui/ConditionalLink";
import { withErrorBoundary } from "@/components/ui/ErrorBoundary";
import { EntityIconWithPlaceholder } from "@/components/ui/PlaceholderBackground";
import { useDevTools } from "@/hooks/useUIStore";
import { useEntityStore } from "@/stores/entityStore";
import { useMessageStore } from "@/stores/messageStore";
import { uiStore } from "@/stores/uiStore";
import { AllTags, TagId } from "@/tags/tags";
import {
  CompanyFact,
  EntityType,
  NO_DESCRIPTION,
  PersonProfile,
  PipelineRunStatus,
  ProfilePageSection,
} from "@/types";
import { maybeEntityUrl } from "@/utils/entityUtils";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useStore } from "@nanostores/react";
import { Entity } from "@prisma/client";
import { useState } from "react";
import { twJoin, twMerge } from "tailwind-merge";

export default withErrorBoundary(function EntityHeader({
  status,
  main,
  showAIChat,
}: {
  status: PipelineRunStatus;
  main: boolean;
  showAIChat?: boolean;
}) {
  const entityStore = useEntityStore();
  const entity = useStore(entityStore.entity);
  const updateQueue = useStore(entityStore.updateEntityQueue);
  const attributes = useStore(entityStore.attributes);
  const facts = useStore(entityStore.facts);
  const snapshot = useStore(entityStore.snapshot);
  const companyBrandedBlurb = facts[CompanyFact.BrandedBlurb]?.value;
  const isDev = useDevTools();

  const queueValue = updateQueue[entity.id];
  const isRegenerating = queueValue && !queueValue.resolved;

  const entityProps = { entity, attributes };

  const entityDescription =
    entity.type === EntityType.Company ? companyBrandedBlurb
    : entity.description?.startsWith(NO_DESCRIPTION) ? undefined
    : entity.description;

  const sidebar = useSidebar();

  return (
    <ProfileBubble className="flex flex-col gap-3" section={ProfilePageSection.Highlights}>
      <div className="flex">
        <div className="flex gap-4 items-center flex-1 max-w-full">
          <ProfileEntityImage entity={entity} />
          <div className="flex-1 flex flex-col gap-1 max-w-full overflow-hidden">
            <div className="flex">
              <ConditionalLink
                className={twJoin(
                  "text-lg md:text-2xl font-semibold flex-1 mr-2",
                  !main && "cursor-pointer hover:underline",
                )}
                href={(!main || undefined) && maybeEntityUrl(entity)}
                onClick={() => {
                  sidebar.closeSidebar();
                }}
              >
                {entity.name}
              </ConditionalLink>
              <div className="flex text-gray-400 flex-row flex-wrap sm:justify-end items-center gap-4 max-w-full">
                <div className="hidden sm:flex items-center gap-3">
                  {main && (
                    <div className="hidden md:flex items-center gap-3">
                      <SocialButtons />
                      <SupportButton />
                    </div>
                  )}
                  {!main ?
                    <ConditionalLink
                      className="text-white bg-brand-600 px-2 py-1 rounded hover:bg-brand-700 flex items-center h-min"
                      href={maybeEntityUrl(entity)}
                      onClick={() => {
                        sidebar.closeSidebar();
                      }}
                    >
                      <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />
                      Open Profile
                    </ConditionalLink>
                  : null}
                </div>
                <ProfilePopoverMenu
                  entity={entity}
                  isRegenerating={isRegenerating}
                  status={status}
                  main={main}
                />
              </div>
            </div>
            {entity.type === EntityType.Person ?
              <PersonSubtitle
                skipLastUpdated
                snapshot={snapshot as PersonProfile}
                {...entityProps}
              />
            : <CompanySubtitle skipLastUpdated {...entityProps} />}
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          "flex flex-row gap-2 items-center justify-between md:hidden",
          !main && "md:flex",
        )}
      >
        <SocialButtons />
      </div>

      <div className="text-sm">{entityDescription}</div>

      <FactsList />
      {snapshot?.tags && snapshot.tags.length > 0 && (
        <div className="flex flex-wrap gap-2 text-gray-600">
          {snapshot?.tags?.map((tag) => (
            <div
              key={tag}
              data-tooltip-id="tooltip"
              data-tooltip-content={AllTags[tag as TagId]?.description}
              className="bg-gray-100 rounded-md p-1"
            >
              {AllTags[tag as TagId]?.name || tag}
            </div>
          ))}
        </div>
      )}

      {isDev && showAIChat ?
        <EntityTabs main={main} />
      : <EntityHighlights />}
    </ProfileBubble>
  );
});

function ProfileEntityImage(props: { entity: Entity }) {
  const [open, setOpen] = useState(false);

  const isAdmin = useStore(uiStore.user)?.email?.includes("@distill.fyi");

  return (
    <>
      {isAdmin && <EntityImageEditModal open={open} setOpen={setOpen} />}
      <div
        className={twJoin("h-16 w-16 overflow-hidden flex-shrink-0", isAdmin && "cursor-pointer")}
        onClick={isAdmin ? () => setOpen(true) : undefined}
      >
        <EntityIconWithPlaceholder className="h-16 w-16" imageClassName="h-16 w-16" {...props} />
      </div>
    </>
  );
}
